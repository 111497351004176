import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import { isSameDay, isAfter, isBefore } from 'date-fns';

import { Text } from '../components/Text';
import { CircleButton } from '../components/CircleButton';
import { ChevronLeft } from '../icons/ChevronLeft';
import { ChevronRight } from '../icons/ChevronRight';
import { H2 } from '../components/Text';
import { Box } from '../components/Box';

const CalendarWeekSelect = observer(({ dateModel }) => {
  return (
    <Box mt={40}>
      <Box flex spaceBetween hCenter>
        <div>
          <Text type="normal18" color="main80">
            {dateModel.month} {dateModel.showYear ? `(${dateModel.baseYear})` : ''}
          </Text>
          <H2 bold>Vika {dateModel.week}</H2>
        </div>
        <CalendarForwardBack flex>
          <CircleButton onClick={dateModel.prevWeek}>
            <ChevronLeft />
          </CircleButton>
          <CircleButton onClick={dateModel.nextWeek}>
            <ChevronRight />
          </CircleButton>
        </CalendarForwardBack>
      </Box>
    </Box>
  );
});
const CalendarForwardBack = styled(Box)({
  '> button:first-child': {
    marginRight: 10
  }
});

export const CalendarWeek = observer(({ dateModel, disableFuture = true, notBefore = null }) => {
  const today = new Date();
  return (
    <React.Fragment>
      <CalendarWeekSelect dateModel={dateModel} />
      <CalendarWeekDays>
        {dateModel.days.map((day, index) => {
          const currentDay = isSameDay(day.date, dateModel.selected);
          const afterToday = isAfter(day.date, today);
          const beforeLast = isBefore(day.date, new Date(notBefore));
          const sameBeforeDay = isSameDay(new Date(notBefore), day.date);
          return (
            <CalendarWeekDay
              key={index}
              style={{
                opacity: (disableFuture && afterToday) || beforeLast || sameBeforeDay ? 0.5 : 1
              }}
              active={currentDay}
              onClick={() => {
                if ((disableFuture && afterToday) || beforeLast || sameBeforeDay) return;
                dateModel.selectDate(day.date);
              }}
            >
              <Text
                caps
                type={currentDay ? 'semibold14' : 'normal14'}
                color={currentDay ? 'white' : 'main80'}
              >
                {day.title}
              </Text>
              <Text
                color={currentDay ? 'white' : 'secondary'}
                mt="8"
                type={currentDay ? 'semibold18' : 'normal18'}
              >
                {day.date.getDate()}
              </Text>
            </CalendarWeekDay>
          );
        })}
      </CalendarWeekDays>
    </React.Fragment>
  );
});

const CalendarWeekDays = styled.div(p => ({
  marginTop: '1.5rem',
  display: 'flex',
  //overflow: 'hidden',
  '> div:not(:last-child)': {
    marginRight: '4px'
  }
}));

const CalendarWeekDay = styled.div(p => ({
  transition: 'all 200ms ease-out',
  width: 100 / 7 + '%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '8px 0',
  borderRadius: '6px',
  ...(p.active && {
    backgroundColor: p.theme.button,
    boxShadow: '0px 14px 30px rgba(126, 126, 126, 0.4)'
  })
}));
