import React from 'react';
import { observer } from 'mobx-react-lite';
import { Route } from 'react-router-dom';
import { IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

import { Karvonen } from '../pages/Karvonen';
import { Calendar } from '../pages/Calendar';
import { FoodDiary } from '../pages/FoodDiary';
import { Home } from '../pages/Home';
import { Lectures } from '../pages/Lectures';
import { Measurements } from '../pages/Measurements';
import { Statistics } from '../pages/Statistics';
import { Register } from '../pages/Register';
import { Splash } from '../pages/Splash';
import { EnduranceExerciseStart } from '../pages/EnduranceExerciseStart';
import { EnduranceExerciseMain } from '../pages/EnduranceExerciseMain';
import { StrengthExerciseStretch } from '../pages/StrengthExerciseStretch';
import { StrengthExerciseStart } from '../pages/StrengthExerciseStart';
import { StrengthExerciseMain } from '../pages/StrengthExerciseMain';
import { StrengthExerciseMainDetail } from '../pages/StrengthExerciseMainDetail';
import { StrengthExerciseWarmup } from '../pages/StrengthExerciseWarmup';
import { StretchExerciseStretch } from '../pages/StretchExerciseStretch';
import { StretchExerciseStart } from '../pages/StretchExerciseStart';
import { StretchExerciseMain } from '../pages/StretchExerciseMain';
import { StretchExerciseMainDetail } from '../pages/StretchExerciseMainDetail';
import { StretchExerciseWarmup } from '../pages/StretchExerciseWarmup';
import { History } from '../pages/History';

export const Routes = observer(() => {
  return (
    <IonReactRouter>
      <IonRouterOutlet>
        {routeConfig.map((route) => (
          <Route key={route.path} {...route} exact />
        ))}
      </IonRouterOutlet>
    </IonReactRouter>
  );
});

const routeConfig = [
  { path: '/', component: Home },
  { path: '/calendar', component: Calendar },
  { path: '/history', component: History },
  { path: '/diary', component: FoodDiary },
  { path: '/karvonen', component: Karvonen },
  { path: '/exercise/endurance', component: EnduranceExerciseStart },
  { path: '/exercise/endurance/main', component: EnduranceExerciseMain },
  { path: '/exercise/strength', component: StrengthExerciseStart },
  { path: '/exercise/strength/warmup', component: StrengthExerciseWarmup },
  { path: '/exercise/strength/main', component: StrengthExerciseMain },
  { path: '/exercise/strength/main/:id', component: StrengthExerciseMainDetail },
  { path: '/exercise/strength/stretch', component: StrengthExerciseStretch },
  { path: '/exercise/stretch', component: StretchExerciseStart },
  { path: '/exercise/stretch/warmup', component: StretchExerciseWarmup },
  { path: '/exercise/stretch/main', component: StretchExerciseMain },
  { path: '/exercise/stretch/main/:id', component: StretchExerciseMainDetail },
  { path: '/exercise/stretch/stretch', component: StretchExerciseStretch },
  { path: '/lectures', component: Lectures },
  { path: '/measurements', component: Measurements },
  { path: '/register', component: Register },
  { path: '/splash', component: Splash },
  { path: '/statistics', component: Statistics },
];
