import React from 'react';

export const ChevronRight = () => (
  <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 1L8.22549 8.22549C8.42298 8.4223 8.42298 8.74246 8.22616 8.93928L8.22549 8.93995L1 16.1654"
      stroke="#101010"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
