import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { useStore } from '../models/Store';
import { Page } from '../components/Page';
import { BackButton } from '../components/BackButton';
import { Card } from '../components/Card';
import { Text } from '../components/Text';
import { RedAccentLine } from '../components/RedAccentLine';
import { Box } from '../components/Box';
import { Button } from '../components/Button';
import { SplitLine } from '../components/SplitLine';
import { StretchEquipmentImage, numberMap } from '../components/StretchEquipmentImage';
import { ColorChooser } from '../components/ColorChooser';

export const StretchExerciseMainDetail = observer(() => {
  const store = useStore();
  const { id } = useParams();
  const history = useHistory();
  if (!store.nextStrength) return null;
  const set = store.nextStrength.sets.find((set) => set.id === Number(id));
  if (!set) return null;
  const hasImage = numberMap[set.equipment.equipment_no] !== undefined;
  return (
    <Page>
      <BackButton link="/exercise/stretch/main" />
      <Text type="semibold24" mt="40" children="Litur á teygju" />

      <ColorChooser onChange={set.setColor} value={set.newColor === '' ? '' : set.newColor} />

      <Button
        mt="32"
        children="Staðfesta"
        disabled={Number(set.newColor) === 0}
        onClick={() => {
          set.setDone();
          history.push('/exercise/stretch/main');
        }}
      />
      <Card mt="40" condensed>
        <Text children={`Stöð ${set.equipment.equipment_no}`} />
        <Text type="semibold35" mt="12" children={set.equipment.name} />
        <RedAccentLine />

        <Box mt="32" flex justifyContent="space-between">
          <Box flex center column w="50%">
            <Text type="bold22" children={set.repetitions} />
            <Text mt="8" color="main80" children="Endurtekning" />
          </Box>
          <Box flex center column w="50%">
            <Text type="bold22" children={set.percentage} />
            <Text mt="8" color="main80" children="Ákefð" />
          </Box>
        </Box>

        {hasImage && (
          <>
            <Box mt="24" mb="24">
              <SplitLine />
            </Box>

            <Box flex justifyContent="center">
              <StretchEquipmentImage number={set.equipment.equipment_no + 'a'} />
              <StretchEquipmentImage number={set.equipment.equipment_no} />
            </Box>
          </>
        )}
      </Card>
    </Page>
  );
});
