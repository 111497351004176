import React from 'react';

export const ChevronDown = () => (
  <svg width="16" height="9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.563 1.208L8.309 7.461a.437.437 0 01-.618 0h0L1.438 1.208"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
