import React from 'react';
import {
  ResponsiveContainer,
  ComposedChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  ReferenceLine,
} from 'recharts';
import { IonToast } from '@ionic/react';
import { format, parseISO, endOfISOWeek } from 'date-fns';
import { is } from 'date-fns/locale';
import styled, { useTheme } from 'styled-components/macro';
import { observer, useLocalStore } from 'mobx-react-lite';
import { useStore } from '../models/Store';
import { Page } from '../components/Page';
import { PageHeader } from '../components/PageHeader';
import { Box } from '../components/Box';
import { Text } from '../components/Text';
import { EditEnduranceExercise } from '../components/EditEnduranceExercise';
import { Minutes } from '../components/Minutes';

export const History = observer(() => {
  const store = useStore();
  const state = useLocalStore(() => ({
    view: 'histogram',
    setView(view) {
      state.view = view;
    },
  }));
  if (!store.me) return null;

  return (
    <Page>
      <PageHeader title="Saga" backLink="/" />
      <Toggle selected={state.view} setView={state.setView} />
      {state.view === 'log' && <Log />}
      {state.view === 'histogram' && <Histogram />}
    </Page>
  );
});

const Tick = (props) => {
  const { x, y, payload } = props;
  if (!payload.value) {
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={10} textAnchor="middle" fill="#666" fontSize="12">
          Meðal.
        </text>
        <text x={0} y={0} dy={24} textAnchor="middle" fill="#666" fontSize="12">
          Mín.
        </text>
      </g>
    );
  }
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={10} textAnchor="middle" fill="#666" fontSize="12">
        {payload.value.split(';')[0]}
      </text>
      <text x={0} y={0} dy={24} textAnchor="middle" fill="#666" fontSize="12">
        {format(parseISO(payload.value.split(';')[1]), 'd')}
      </text>
    </g>
  );
};

const WeekChart = ({ data }) => {
  const theme = useTheme();
  return (
    <ChartContainer>
      <ResponsiveContainer width="100%" height={200}>
        <ComposedChart data={data.dates} margin={{ top: 20, right: 0, bottom: 20, left: -25 }}>
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis dataKey="dateStr" axisLine={false} tickLine={false} interval={0} tick={<Tick />} />
          <YAxis axisLine={false} tickLine={false} />
          <Bar
            minPointSize={0}
            dataKey="minutes"
            fill={theme.orange}
            label={{ position: 'center', fill: '#fff', fontSize: 12 }}
          />
          <Bar minPointSize={0} dataKey="strength" fill={theme.purple} />
          <ReferenceLine y={30} stroke={theme.red} ifOverflow="extendDomain" />
        </ComposedChart>
      </ResponsiveContainer>
    </ChartContainer>
  );
};

const Histogram = observer(() => {
  const store = useStore();
  return (
    <>
      <Box mt="24" mb="24">
        <Minutes />
      </Box>
      {store.me.weekMinutes.map((it) => (
        <Box key={it.start} mt="24">
          <Box flex mb="8">
            <Text type="semibold14">{format(it.start, 'd. MMMM yyyy', { locale: is })}</Text>
            <Text type="semibold14" ml="8" mr="8">
              -
            </Text>
            <Text type="semibold14">
              {format(endOfISOWeek(it.start), 'd. MMMM yyyy', { locale: is })}
            </Text>
          </Box>
          <WeekChart data={it} />
        </Box>
      ))}
    </>
  );
});

const Log = observer(() => {
  const store = useStore();
  const state = useLocalStore(() => ({
    editing: null,
    showEditSuccess: false,
    showRemoveSuccess: false,
    openModal(item) {
      state.editing = item;
    },
    closeModal() {
      state.editing = null;
    },
    onSuccess() {
      state.closeModal();
      state.setShowEditSuccess(true);
    },
    onRemoveSuccess() {
      state.closeModal();
      state.setShowRemoveSuccess(true);
    },
    setShowEditSuccess(val) {
      state.showEditSuccess = val;
    },
    setShowRemoveSuccess(val) {
      state.showRemoveSuccess = val;
    },
  }));

  if (store.me.endurance_exercises.length === 0) {
    return (
      <Box mt="24">
        <Text>Engar æfingar hafa verið skráðar</Text>
      </Box>
    );
  }

  return (
    <Box mt="24">
      <Box flex alignItems="center">
        <Text type="normal14">Fjöldi skráðra þolæfinga:</Text>
        <Text type="semibold14" ml="4">
          {store.me.endurance_exercises.length}
        </Text>
      </Box>
      <Text mt="4" mb="24" type="normal14">
        Smelltu á æfingu til að breyta eða eyða.
      </Text>
      <Header>
        <div>Dags.</div>
        <div>Æfing</div>
        <div>Mín.</div>
      </Header>
      <Entries>
        {store.me.endurance_exercises.map((es) => (
          <Entry onClick={() => state.openModal(es)} key={es.id}>
            <div>{format(new Date(es.date), 'dd.MM.yyyy', { locale: is })}</div>
            <div>{es.endurance_type}</div>
            <div>{es.minutes}</div>
          </Entry>
        ))}
      </Entries>
      <IonToast
        isOpen={state.showEditSuccess}
        header="Aðgerð tókst"
        message="Æfing hefur verið vistuð."
        duration={4000}
        mode="md"
        color="success"
        onDidDismiss={() => state.setShowEditSuccess(false)}
      />
      <IonToast
        isOpen={state.showRemoveSuccess}
        header="Aðgerð tókst"
        message="Æfingu hefur verið eytt."
        duration={4000}
        mode="md"
        color="success"
        onDidDismiss={() => state.setShowRemoveSuccess(false)}
      />
      {state.editing && (
        <EditEnduranceExercise
          onClose={state.closeModal}
          item={state.editing}
          onSuccess={state.onSuccess}
          onRemoveSuccess={state.onRemoveSuccess}
        />
      )}
    </Box>
  );
});

const Header = styled.div((p) => ({
  marginBottom: 10,
  display: 'grid',
  gridTemplateColumns: '0.6fr 1fr 0.3fr',
  gridGap: 10,
  padding: '0 10px',
  ...p.theme.text.semibold16,
  '> div:last-child': {
    textAlign: 'right',
  },
}));

const Entries = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridGap: 1,
});

const Entry = styled.div((p) => ({
  background: '#fff',
  //borderRadius: '5px',
  padding: '15px 10px',
  display: 'grid',
  gridTemplateColumns: '0.6fr 1fr 0.3fr',
  boxShadow: '0px 1px 1px rgba(126, 126, 126, 0.4)',
  gridGap: 10,
  '> div:last-child': {
    textAlign: 'right',
  },
  ...p.theme.text.normal14,
}));

const Toggle = observer(({ selected, setView }) => {
  return (
    <ToggleContainer>
      <ToggleItem>
        <ToggleContent
          onClick={() => setView('histogram')}
          active={selected === 'histogram'}
          children="Yfirsýn"
        />
      </ToggleItem>
      <ToggleItem>
        <ToggleContent
          onClick={() => setView('log')}
          active={selected === 'log'}
          children="Skráningar"
        />
      </ToggleItem>
    </ToggleContainer>
  );
});

const ToggleContainer = styled.div((p) => ({
  backgroundColor: p.theme.button,
  borderRadius: 10,
  width: '100%',
  display: 'flex',
  overflow: 'hidden',
  padding: 8,
}));

const ToggleItem = styled.div({
  flex: 1,
});

const ToggleContent = styled.div((p) => ({
  transition: 'all 0.4s',
  color: p.active ? p.theme.secondary : p.theme.white,
  backgroundColor: p.active ? p.theme.white : p.theme.button,
  borderRadius: 7,
  textAlign: 'center',
  padding: 5,
  fontWeight: p.theme.bold,
}));

const ChartContainer = styled.div((p) => ({
  padding: '20px 15px 0 15px',
  backgroundColor: '#fff',
  borderRadius: 10,
  flex: 1,
}));
