import { types as t } from 'mobx-state-tree';
import {
  addDays,
  addWeeks,
  endOfISOWeek,
  getISOWeek,
  getMonth,
  getYear,
  startOfISOWeek,
  subWeeks,
} from 'date-fns';

export const CalendarWeekView = t
  .model('CalendarWeekView', {
    currentViewBase: new Date(),
    selected: new Date(),
  })
  .actions((self) => ({
    selectDate(date) {
      self.selected = date;
    },
    resetBase() {
      self.currentViewBase = new Date();
    },
    nextWeek() {
      self.currentViewBase = addWeeks(self.currentViewBase, 1);
      self.selected = new Date();
    },
    prevWeek() {
      self.currentViewBase = subWeeks(self.currentViewBase, 1);
      self.selected = new Date();
    },
  }))
  .views((self) => ({
    get baseYear() {
      return getYear(self.currentViewBase);
    },
    get showYear() {
      const selectedWeek = startOfISOWeek(self.selected);
      const viewBaseWeek = startOfISOWeek(self.currentViewBase);
      const selectedYear = getYear(self.selected);
      const viewBaseYear = getYear(self.currentViewBase);
      return selectedYear !== viewBaseYear && selectedWeek !== viewBaseWeek;
    },
    get month() {
      const startMonth = getMonth(startOfISOWeek(self.currentViewBase));
      const endMonth = getMonth(endOfISOWeek(self.currentViewBase));
      if (startMonth !== endMonth) {
        return `${monthMap[startMonth]} - ${monthMap[endMonth]}`;
      }
      return monthMap[getMonth(startOfISOWeek(self.currentViewBase))];
    },
    get week() {
      return getISOWeek(self.currentViewBase);
    },
    get days() {
      const first = startOfISOWeek(self.currentViewBase);
      return [
        { title: 'Mán', date: first },
        { title: 'Þri', date: addDays(first, 1) },
        { title: 'Mið', date: addDays(first, 2) },
        { title: 'Fim', date: addDays(first, 3) },
        { title: 'Fös', date: addDays(first, 4) },
        { title: 'Lau', date: addDays(first, 5) },
        { title: 'Sun', date: addDays(first, 6) },
      ];
    },
  }));
const monthMap = [
  'Janúar',
  'Febrúar',
  'Mars',
  'Apríl',
  'Maí',
  'Júní',
  'Júlí',
  'Ágúst',
  'September',
  'Október',
  'Nóvember',
  'Desember',
];
