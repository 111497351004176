import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import { Page } from '../components/Page';
import { PageHeader } from '../components/PageHeader';
import { Card } from '../components/Card';
import { Text } from '../components/Text';
import { Box } from '../components/Box';
import { CircleButton } from '../components/CircleButton';
import { ChevronDown } from '../icons/ChevronDown';
import { ChevronUp } from '../icons/ChevronUp';
import { Button } from '../components/Button';
import { toISformatting } from '../helpers/numbers';
import { useStore } from '../models/Store';
import { mapper, sectionKeys } from '../mapper';

export const Measurements = observer(() => {
  const store = useStore();
  const [open, setOpen] = React.useState(() => {
    let res = {};
    Array.from(mapper.keys()).forEach(key => (res[key] = false));
    return res;
  });

  const allOpen = Object.keys(open).every(key => open[key]);
  const openAll = () => {
    setOpen(old => {
      let res = {};
      Object.keys(old).forEach(key => (res[key] = true));
      return res;
    });
  };
  const closeAll = () => {
    setOpen(old => {
      let res = {};
      Object.keys(old).forEach(key => (res[key] = false));
      return res;
    });
  };

  const toggleOpen = key => setOpen(old => ({ ...old, [key]: !old[key] }));
  if (!store.me) return null;
  return (
    <Page>
      <PageHeader title="Mælingar" />
      <Box mt="32">
        {!allOpen ? (
          <Button onClick={openAll}>Opna allar mælingar</Button>
        ) : (
          <Button onClick={closeAll}>Loka öllum mælingum</Button>
        )}
      </Box>
      {sectionKeys.map(([sectionTitle, keys], index) => (
        <Box mt="32" key={sectionTitle}>
          <Text type="normal24" mb="16" children={sectionTitle} />
          {keys.map(key => (
            <MeasurementItem
              key={key}
              data={store.me.measurements.get(key)}
              color={colors[index % colors.length]}
              dataKey={key}
              open={open[key]}
              toggle={toggleOpen}
            />
          ))}
        </Box>
      ))}
    </Page>
  );
});

const MeasurementItem = observer(({ dataKey, open, toggle, color, data }) => {
  const store = useStore();
  if (!data || (data.length === 1 && data[0] === '')) {
    return null;
  }
  const mapData = mapper.get(dataKey);
  const title = mapData.subSection
    ? `${mapData.subSection}; ${mapData.title}`
    : `${mapData.title || mapData.title}`;
  let rowContainers = [];
  data.forEach((val, key) => {
    let value = val;
    if (mapData.render) {
      value = mapData.render(val);
    } else {
      value = isNaN(val) ? val : toISformatting(Number(val), decimals[dataKey]);
    }
    const date = store.getDateByMeasurement(key);
    rowContainers.push(
      <RowContainer key={key}>
        <div>
          {key}. mæling ({date})
        </div>
        <Box flex alignItems="flex-end">
          <Text>{value}</Text>
          <Text ml="4" type="normal14" style={{ textTransform: 'none' }}>
            {units[dataKey]}
          </Text>
        </Box>
      </RowContainer>
    );
  });
  return (
    <Card condensed accentColor={color} mt="12">
      <Box flex spaceBetween alignItems="center">
        <Text type="semibold18" children={title} mr="12" />
        <CircleButton
          bgColor={color}
          color="white"
          onClick={() => toggle(dataKey)}
          style={{ minWidth: 45 }}
        >
          {!open ? <ChevronDown /> : <ChevronUp />}
        </CircleButton>
      </Box>
      {open && <RowMeasurements>{rowContainers}</RowMeasurements>}
    </Card>
  );
});

const RowMeasurements = styled.div(p => ({
  marginTop: 12,
  '> div:not(:last-child)': {
    borderBottom: '1px solid #F4F4F4'
  }
}));

const RowContainer = styled.div(p => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '8px 0'
}));

const colors = ['red', 'orange', 'blue', 'purple', 'green'];

const decimals = {
  sbp: 0,
  dbp: 0,
  hrr: 0,
  bmi: 2,
  whr: 2,
  height: 1,
  weight: 2,
  waist: 1,
  hips: 1,
  mass: 2,
  fatmass: 2,
  freemass: 2,
  fatperc: 2,
  stretchRight: 2,
  stretchLeft: 2,
  behindRTL: 2,
  behindLTR: 2,
  movbal: 2
};

const units = {
  height: 'cm',
  weight: 'kg',
  waist: 'cm',
  hips: 'cm',
  mass: 'kg',
  bmi: 'kg/m²',
  fatmass: 'kg',
  freemass: 'kg',
  fatperc: '%',
  hrr: 'slög/mín',
  sbp: 'mmHg',
  dbp: 'mmHg',
  stretchRight: 'cm',
  stretchLeft: 'cm',
  behindRTL: 'cm',
  behindLTR: 'cm',
  movbal: 'sek'
};
