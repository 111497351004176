import React from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Page } from '../components/Page';
import { BackButton } from '../components/BackButton';
import { Box } from '../components/Box';
import { ProgressSteps } from '../components/ProgressSteps';
import { Text } from '../components/Text';
import { Card } from '../components/Card';
import { Button } from '../components/Button';
import { SplitLine } from '../components/SplitLine';
import { useStore } from '../models/Store';
import ganga from '../images/ganga.png';

export const StrengthExerciseStretch = observer(() => {
  const store = useStore();
  const history = useHistory();
  if (!store.nextStrength) return null;
  return (
    <Page>
      <BackButton link="/exercise/strength/main" />
      <Box mt="32">
        <ProgressSteps count={3} current={2} />
      </Box>
      <Text type="semibold24" mt="32" children="Niðurlag" />
      <Card mt="24" style={{ textAlign: 'center' }}>
        <Box column flex center>
          <img src={ganga} alt="Ganga eða hjóla rólega" />
          <Text mt="24" type="semibold30" children="Ganga eða hjóla rólega" />
          <Text mt="12" type="normal22" children="10 - 15 mín" />
          <SplitLine />
          <Text mt="12" children="Muna að liðkun, teygjur og slökun er allra meina bót." />
        </Box>
      </Card>
      <Button
        mt="32"
        children="Klára æfingu"
        onClick={() => {
          store.finishStrengthExercise();
          history.push('/');
        }}
      />
    </Page>
  );
});
