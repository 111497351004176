import React from 'react';
import { observer } from 'mobx-react-lite';
import armbeygja from '../images/teygja_armbeygja.png';
import armbeygja_a from '../images/teygja_armbeygja_a.png';
import armretta from '../images/teygja_armretta.png';
import armretta_a from '../images/teygja_armretta_a.png';
import framanverdlaeri from '../images/teygja_framanverdlaeri.png';
import framanverdlaeri_a from '../images/teygja_framanverdlaeri_a.png';
import aftanverdlaeri from '../images/teygja_aftanverdlaeri.png';
import aftanverdlaeri_a from '../images/teygja_aftanverdlaeri_a.png';
import axlir from '../images/teygja_axlir.png';
import axlir_a from '../images/teygja_axlir_a.png';
import bakvodvar_nidurtog from '../images/teygja_bakvodvar_nidurtog.png';
import bakvodvar_nidurtog_a from '../images/teygja_bakvodvar_nidurtog_a.png';
import bakvodvar_rodur from '../images/teygja_bakvodvar_rodur.png';
import bakvodvar_rodur_a from '../images/teygja_bakvodvar_rodur_a.png';
import brjostvodvar from '../images/teygja_brjostvodvar.png';
import brjostvodvar_a from '../images/teygja_brjostvodvar_a.png';
import fotapressa from '../images/teygja_fotapressa.png';
import fotapressa_a from '../images/teygja_fotapressa_a.png';
import kalfapressa from '../images/teygja_kalfapressa.png';
import kalfapressa_a from '../images/teygja_kalfapressa_a.png';
import innanverdlaeri from '../images/teygja_innanverdlaeri.png';
import innanverdlaeri_a from '../images/teygja_innanverdlaeri_a.png';
import utanverdlaeri from '../images/teygja_utanverdlaeri.png';
import utanverdlaeri_a from '../images/teygja_utanverdlaeri_a.png';
import kvidaefing from '../images/teygja_abs.png';
import kvidaefing_a from '../images/teygja_abs_a.png';
import bakaefing from '../images/teygja_bakaefing.png';
import bakaefing_a from '../images/teygja_bakaefing_a.png';

export const StretchEquipmentImage = observer(({ number }) => {
  const image = numberMap[number];
  if (!image) return null;
  return <img src={image} width="150" alt="mynd æfingar" />;
});

export const numberMap = {
  1: armbeygja,
  '1a': armbeygja_a,
  2: armretta,
  '2a': armretta_a,
  3: framanverdlaeri,
  '3a': framanverdlaeri_a,
  4: aftanverdlaeri,
  '4a': aftanverdlaeri_a,
  5: brjostvodvar,
  '5a': brjostvodvar_a,
  6: bakvodvar_rodur,
  '6a': bakvodvar_rodur_a,
  7: innanverdlaeri,
  '7a': innanverdlaeri_a,
  8: utanverdlaeri,
  '8a': utanverdlaeri_a,
  9: bakvodvar_nidurtog,
  '9a': bakvodvar_nidurtog_a,
  10: axlir,
  '10a': axlir_a,
  11: fotapressa,
  '11a': fotapressa_a,
  12: kalfapressa,
  '12a': kalfapressa_a,

  13: kvidaefing,
  '13a': kvidaefing_a,
  14: bakaefing,
  '14a': bakaefing_a,
};
