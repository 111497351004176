import React from 'react';
import styled from 'styled-components/macro';
import banner from '../images/register-banner.svg';
import { Page } from '../components/Page';
import { Box } from '../components/Box';
import { H1, FormLabel } from '../components/Text';
import { Input } from '../components/Input';
import { Button } from '../components/Button';

export const Register = () => {
  return (
    <Page style={{ padding: 0 }}>
      <Box flex column style={{ minHeight: '100vh' }}>
        <div style={{ padding: '0 20px', zIndex: 2 }}>
          <img src={banner} style={{ width: '100%' }} alt="people exercise" />
        </div>
        <RegisterContent>
          <Box mt={30} flex center>
            <H1 color="white">Nýr notandi</H1>
          </Box>

          <Box mt={30}>
            <FormLabel color="white">Notandanafn</FormLabel>
            <Input placeholder="Notandanafnið þitt" />
          </Box>

          <Box mt={30}>
            <FormLabel color="white">Lykilorð</FormLabel>
            <Input placeholder="Lykilorðið þitt" />
          </Box>

          <Box mt={30}>
            <FormLabel color="white">Staðfest lykilorð</FormLabel>
            <Input placeholder="Skrifaður lykilorðið aftur" />
          </Box>

          <Box mt={40}>
            <Button>Staðfesta</Button>
          </Box>
        </RegisterContent>
      </Box>
    </Page>
  );
};

const RegisterContent = styled.div(p => ({
  backgroundColor: p.theme.main,
  padding: '0 25px',
  flex: 1,
  marginTop: -13,
  width: '100%'
}));
