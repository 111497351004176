import React from 'react';

export const StretchIcon = (props) => (
  <svg width="78" height="78" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x=".816"
      y="51.728"
      width="44"
      height="12"
      rx="1"
      transform="rotate(-45 .816 51.728)"
      fill="#FF6161"
    />
    <path
      d="M38.842 28.25L34.05 45.363l-7.013-7.014a1 1 0 01-.257-.971l4.398-16.012.044-.045a1 1 0 011.414 0l5.95 5.95a1 1 0 01.256.977z"
      fill="#FF8989"
    />
    <rect
      x="26.272"
      y="37.586"
      width="44"
      height="12"
      rx="1"
      transform="rotate(-45 26.272 37.586)"
      fill="#FF6161"
    />
    <rect
      x="16.373"
      y="67.284"
      width="44"
      height="6"
      rx="1"
      transform="rotate(-45 16.373 67.284)"
      fill="#FDBF00"
    />
    <path
      d="M51.337 41.587l-5.266 15.798-4.284-4.284 4.991-16.222a1 1 0 011.414 0l2.662 2.661a2 2 0 01.483 2.047z"
      fill="#FFDA2D"
    />
    <rect
      x="41.121"
      y="53.849"
      width="44"
      height="6"
      rx="1"
      transform="rotate(-45 41.121 53.85)"
      fill="#FDBF00"
    />
  </svg>
);
