import React from 'react';

export const HeartbeatIcon = () => (
  <svg width="63" height="63" viewBox="0 0 63 63" fill="none">
    <path
      d="M47.2254 7.4417C40.9872 6.26045 35.3023 9.47197 31.5 16.1534C27.6977 9.47197 22.0498 6.29749 15.8115 7.47874C8.98254 8.84456 3.81445 15.489 3.81445 22.9457C3.81445 32.6172 11.6771 39.1878 23.6004 49.1546C25.7042 50.8894 27.9565 52.7351 30.2816 54.7657L31.5 55.7991L32.7184 54.7657C35.0435 52.7353 37.2958 50.8896 39.3996 49.1546C51.3227 39.1878 59.1855 32.6172 59.1855 22.9457C59.1855 15.3413 54.1652 8.80752 47.2254 7.4417Z"
      fill="#FF8597"
    />
    <path
      d="M59.1855 22.9457C59.1855 32.6172 51.3229 39.1878 39.3996 49.1546C37.2958 50.8894 35.0435 52.7351 32.7184 54.7657L31.5 55.7991V16.1534C35.3023 9.47197 40.9872 6.26045 47.2254 7.4417C54.1652 8.80764 59.1855 15.3414 59.1855 22.9457Z"
      fill="#FF637B"
    />
    <path
      d="M63 33.0601V36.7515H41.984L39.1045 30.3284L36.4098 36.7515H30.6511L25.4091 44.6142L20.4258 29.6639L18.0633 36.7515H0V33.0601H15.4055L20.4258 17.999L26.5167 36.2716L28.6575 33.0601H33.973L38.9568 21.0261L44.383 33.0601H63Z"
      fill="#FFCEBF"
    />
    <path
      d="M63 33.0604V36.7518H41.984L39.1045 30.3287L36.4098 36.7518H31.5V33.0604H33.973L38.9568 21.0264L44.383 33.0604H63Z"
      fill="#FFB99C"
    />
  </svg>
);
