import React from 'react';
import styled from 'styled-components/macro';

const checkNegative = (margin, theme) => {
  const str = margin.toString();
  if (str.startsWith('-')) {
    return '-' + theme.spacing[`m${margin.slice(1)}`];
  }
  return theme.spacing[`m${margin}`];
};

const margins = p => ({
  ...(p.mt && { marginTop: checkNegative(p.mt, p.theme) }),
  ...(p.mr && { marginRight: checkNegative(p.mr, p.theme) }),
  ...(p.mb && { marginBottom: checkNegative(p.mb, p.theme) }),
  ...(p.ml && { marginLeft: checkNegative(p.ml, p.theme) }),
  ...(p.margin && { margin: checkNegative(p.margin, p.theme) })
});

const padding = p => ({
  ...(p.pt && { paddingTop: p.theme.spacing[`m${p.pt}`] }),
  ...(p.pr && { paddingRight: p.theme.spacing[`m${p.pr}`] }),
  ...(p.pb && { paddingBottom: p.theme.spacing[`m${p.pb}`] }),
  ...(p.pl && { paddingLeft: p.theme.spacing[`m${p.pl}`] }),
  ...(p.padding && { padding: p.theme.spacing[`m${p.padding}`] })
});

export const Box = styled(
  ({
    flex,
    wrap,
    alignItems,
    justifyContent,
    grow,
    flexDirection,
    m,
    w,
    h,
    mt,
    mb,
    ml,
    mr,
    color,
    type,
    active,
    center,
    spaceBetween,
    hCenter,
    vCenter,
    column,
    ...rest
  }) => <div {...rest} />
)(p => ({
  display: p.flex ? 'flex' : 'block',
  justifyContent: p.justifyContent || 'flex-start',
  alignItems: p.alignItems || 'flex-start',
  flexDirection: p.flexDirection || 'row',
  ...(p.w && { width: p.theme.spacing[`m${p.w}`] || p.w }),
  ...(p.h && { height: p.theme.spacing[`m${p.h}`] || p.h }),
  ...(p.m && { margin: p.m }),
  ...(p.grow && { flexGrow: 1 }),
  ...(p.wrap && { flexWrap: 'wrap' }),
  ...(p.column && { flexDirection: 'column' }),
  ...(p.hCenter && { alignItems: 'center' }),
  ...(p.vCenter && { justifyContent: 'center' }),
  ...(p.center && { justifyContent: 'center', alignItems: 'center' }),
  ...(p.spaceBetween && { justifyContent: 'space-between' }),
  ...(p.color && { color: p.theme[p.color] }),
  ...(p.onClick && { cursor: 'pointer' }),
  ...margins(p),
  ...padding(p)
}));
