import React from 'react';
import { observer } from 'mobx-react-lite';
import styled, { useTheme } from 'styled-components';
import {
  ResponsiveContainer,
  ComposedChart,
  ReferenceLine,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
} from 'recharts';
import { Text } from './Text';
import { useStore } from '../models/Store';

export const Minutes = observer(() => {
  const theme = useTheme();
  const { me } = useStore();
  if (!me.average_minutes_since_first_exercise) return null;

  const data = [
    {
      name: 'Frá fyrstu æfingu',
      Viðmið: 30,
      Mínútur: Math.floor(me.average_minutes_since_first_exercise),
    },
    { name: 'Síðustu 7 daga', Viðmið: 30, Mínútur: Math.floor(me.average_minutes_last_7_days) },
  ];

  return (
    <Container>
      <Text type="semibold18">Meðalfjöldi mínútna á dag</Text>
      <Text mt="4" type="normal14">
        Æskilegt: 30 mínútur
      </Text>
      <ResponsiveContainer width="100%" height={200}>
        <ComposedChart data={data} margin={{ top: 20, right: 0, bottom: 20, left: -30 }}>
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis dataKey="name" axisLine={false} tickLine={false} />
          <YAxis axisLine={false} tickLine={false} />
          <Bar
            minPointSize={1}
            dataKey="Mínútur"
            barSize={60}
            fill={theme.orange}
            label={{ position: 'center', fill: '#fff', fontSize: 12 }}
          />
          <ReferenceLine y={30} stroke={theme.red} ifOverflow="extendDomain" />
        </ComposedChart>
      </ResponsiveContainer>
    </Container>
  );
});

const Container = styled.div((p) => ({
  padding: '20px 15px 0 15px',
  backgroundColor: '#fff',
  borderRadius: 10,
  flex: 1,
}));
