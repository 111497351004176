import React from 'react';
import {
  format,
  parseISO,
  isAfter,
  subWeeks,
  startOfISOWeek,
  addDays,
  endOfISOWeek,
} from 'date-fns';
import { types as t, flow, applySnapshot, destroy } from 'mobx-state-tree';
import axios from 'axios';
import { api } from '../api';
import { CalendarWeekView } from './CalendarWeekView';

const EnduranceExerciseLog = t
  .model('EnduranceExerciseLog', {
    id: t.number,
    endurance_type: t.string,
    date: t.string,
    minutes: t.number,
  })
  .actions((self) => ({
    update(data) {
      applySnapshot(self, data);
    },
  }));

const StrengthExerciseLog = t.model('StrengthExerciseLog', {
  id: t.number,
  date: t.string,
  is_done: t.boolean,
});

const Me = t
  .model('Me', {
    measurements: t.map(t.map(t.string)),
    measurement_dates: t.map(t.string),
    endurance_exercises: t.array(EnduranceExerciseLog),
    strength_exercises: t.array(StrengthExerciseLog),
    karvonen_50: t.maybeNull(t.number),
    karvonen_55: t.maybeNull(t.number),
    karvonen_60: t.maybeNull(t.number),
    karvonen_65: t.maybeNull(t.number),
    karvonen_70: t.maybeNull(t.number),
    karvonen_75: t.maybeNull(t.number),
    karvonen_80: t.maybeNull(t.number),
    karvonen_85: t.maybeNull(t.number),
    karvonen_90: t.maybeNull(t.number),
    average_minutes_last_7_days: t.maybeNull(t.number),
    average_minutes_since_first_exercise: t.maybeNull(t.number),
    name: t.maybeNull(t.string),
    active: t.maybeNull(t.boolean),
  })
  .views((self) => ({
    get weekMinutes() {
      let first = self.endurance_exercises[self.endurance_exercises.length - 1];
      if (!first) first = { date: new Date() };
      const mins = self.endurance_exercises.reduce((obj, item) => {
        const dt = parseISO(item.date);
        if (obj[dt] === undefined) obj[dt] = 0;
        obj[dt] += item.minutes;
        return obj;
      }, {});
      const strengths = self.strength_exercises.reduce((obj, item) => {
        const dt = parseISO(item.date);
        if (obj[dt] === undefined) obj[dt] = 0;
        obj[dt] = 30;
        return obj;
      }, {});

      const oldest = new Date(first.date);
      const newest = endOfISOWeek(new Date());

      let res = [];
      let current = newest;

      while (isAfter(current, oldest)) {
        const week = startOfISOWeek(current);
        const inner = {
          start: week,
          dates: [
            {
              date: week,
              minutes: mins[week] || 0,
              strength: strengths[week] || 0,
              dateStr: 'Mán;' + week.toISOString(),
            },
            {
              date: addDays(week, 1),
              minutes: mins[addDays(week, 1)] || 0,
              strength: strengths[addDays(week, 1)] || 0,
              dateStr: 'Þri;' + addDays(week, 1).toISOString(),
            },
            {
              date: addDays(week, 2),
              minutes: mins[addDays(week, 2)] || 0,
              strength: strengths[addDays(week, 2)] || 0,
              dateStr: 'Mið;' + addDays(week, 2).toISOString(),
            },
            {
              date: addDays(week, 3),
              minutes: mins[addDays(week, 3)] || 0,
              strength: strengths[addDays(week, 3)] || 0,
              dateStr: 'Fim;' + addDays(week, 3).toISOString(),
            },
            {
              date: addDays(week, 4),
              minutes: mins[addDays(week, 4)] || 0,
              strength: strengths[addDays(week, 4)] || 0,
              dateStr: 'Fös;' + addDays(week, 4).toISOString(),
            },
            {
              date: addDays(week, 5),
              minutes: mins[addDays(week, 5)] || 0,
              strength: strengths[addDays(week, 5)] || 0,
              dateStr: 'Lau;' + addDays(week, 5).toISOString(),
            },
            {
              date: addDays(week, 6),
              minutes: mins[addDays(week, 6)] || 0,
              strength: strengths[addDays(week, 6)] || 0,
              dateStr: 'Sun;' + addDays(week, 6).toISOString(),
            },
          ],
        };
        const sum = inner.dates.reduce((sum, it) => sum + it.minutes, 0);
        inner.dates.push({
          date: undefined,
          minutes: (sum / 7).toFixed(),
        });
        res.push(inner);
        current = subWeeks(current, 1);
      }
      return res;
    },
  }));

const StrengthExerciseSetEquipment = t.model('StrengthExerciseSetEquipment', {
  name: t.string,
  equipment_no: t.number,
  vimeo_id: t.maybeNull(t.string),
});

const StrengthExerciseSet = t
  .model('StrengthExerciseSet', {
    id: t.number,
    repetitions: t.string,
    percentage: t.string,
    last_weight: t.maybeNull(t.string),
    weight: t.maybeNull(t.string),
    color: t.maybeNull(t.string),
    equipment: StrengthExerciseSetEquipment,

    // local state
    done: false,
    newWeight: '0',
    newColor: '',
  })
  .actions((self) => ({
    afterCreate() {
      if (self.weight !== null) {
        self.newWeight = self.weight;
        self.done = true;
      } else if (self.weight === null && self.last_weight !== null) {
        self.newWeight = self.last_weight;
      }
    },
    setDone() {
      api.update_strength_exercise_set(self.id, {
        weight: Number(self.newWeight),
        color: self.newColor,
      });
      self.done = true;
    },
    setWeight(newWeight) {
      if (newWeight === '0') {
        self.newWeight = '';
      } else {
        self.newWeight = newWeight;
      }
    },
    setColor(newColor) {
      self.newColor = newColor;
    },
    increaseWeight() {
      if (!self.newWeight) {
        self.newWeight = '5';
      } else {
        self.newWeight = String(Number(self.newWeight) + 5);
      }
    },
    decreaseWeight() {
      if (self.newWeight && Number(self.newWeight) >= 5) {
        self.newWeight = String(Number(self.newWeight) - 5);
      }
    },
  }));

const Override = t
  .model('Override', {
    id: t.number,
    vimeo_id: t.maybeNull(t.string),
    text: t.maybeNull(t.string),
    hide_equipment: t.boolean,
    participant: t.maybeNull(t.union(t.number, t.string)),
    group: t.maybeNull(t.number),
    equipment: t.number
  })

const StrengthExercise = t
  .model('StrengthExercise', {
    id: t.number,
    date: t.maybeNull(t.string),
    feeling: t.maybeNull(t.string),
    last_exercise_date: t.maybeNull(t.string),
    is_done: false,
    sets: t.array(StrengthExerciseSet),
    overrides: t.array(Override),
    equipment_vimeo_ids: t.maybeNull(t.map(t.maybeNull(t.string))),
  })
  .views((self) => ({
    get howManyCompleted() {
      return self.sets.filter((set) => set.done).length;
    },
  }));

export const Store = t
  .model('Store', {
    me: t.maybe(Me),
    token: t.maybe(t.string),
    settingsModalOpen: false,
    nextStrength: t.maybe(StrengthExercise),
    exerciseDate: t.optional(CalendarWeekView, {}),
    enduranceType: '',
    enduranceOther: '',
    enduranceMinutes: 0,
  })
  .actions((self) => ({
    afterCreate() {
      const storedToken = localStorage.getItem('__jh_tk');
      if (storedToken) {
        self.setToken(storedToken);
      }
    },
    setEnduranceOther(val) {
      self.enduranceOther = val;
    },
    setEnduranceType(enduranceType) {
      self.enduranceType = enduranceType;
    },
    startStrengthExercise: flow(function* () {
      const res = yield api.update_strength_exercise(self.nextStrength.id, {
        date: format(self.exerciseDate.selected, 'yyyy-MM-dd'),
      });
      return !res.error;
    }),
    increaseEnduranceMinutes() {
      self.enduranceMinutes += 5;
    },
    decreaseEnduranceMinutes() {
      self.enduranceMinutes = Math.max(0, self.enduranceMinutes - 5);
    },
    setEnduranceMinutes(mins) {
      self.enduranceMinutes = Number(mins);
    },
    logOut() {
      localStorage.removeItem('__jh_tk');
      self.token = undefined;
      window.location.reload();
    },
    setDateFromNextExercise() {
      if (self.nextStrength && self.nextStrength.date) {
        self.exerciseDate.selectDate(new Date(self.nextStrength.date));
      }
    },
    finishStrengthExercise: flow(function* () {
      const res = yield api.update_strength_exercise(self.nextStrength.id, {
        is_done: true,
        date: format(self.exerciseDate.selected, 'yyyy-MM-dd'),
      });
      self.loadMe();
      self.loadNextStrength();
      return !res.error;
    }),
    finishEnduranceExercise: flow(function* () {
      const res = yield api.create_endurance_exercise({
        date: format(self.exerciseDate.selected, 'yyyy-MM-dd'),
        endurance_type: self.enduranceType === 'annad' ? self.enduranceOther : self.enduranceType,
        minutes: self.enduranceMinutes,
      });
      self.loadMe();
      return !res.error;
    }),
    openSettingsModal() {
      self.settingsModalOpen = true;
    },
    closeSettingsModal() {
      self.settingsModalOpen = false;
    },
    loadNextStrength: flow(function* () {
      const res = yield api.get_next_strength_exercise();
      if (!res.error && res.data.sets && res.data.sets.length > 0) {
        self.nextStrength = res.data;
      } else {
        self.nextStrength = undefined;
      }
    }),
    removeEnduranceExercise(id) {
      const exercise = self.me.endurance_exercises.find((it) => it.id === id);
      destroy(exercise);
    },
    updateEnduranceExercise(id, data) {
      const exercise = self.me.endurance_exercises.find((it) => it.id === id);
      exercise.update(data);
    },
    loadMe: flow(function* () {
      const res = yield api.me();
      if (!res.error) {
        const measurement_dates = transformMeasurementDates(res.data.measurements);
        res.data.measurements = transformMeasurements(res.data.measurements);
        self.me = res.data;
        self.me.measurement_dates = measurement_dates;
      }
      console.log(res)
      if (res.status === 401) {
        self.logOut();
      }
    }),
    setToken(token) {
      localStorage.setItem('__jh_tk', token);
      axios.defaults.headers.common['Authorization'] = `Token ${token}`;
      self.token = token;
      self.loadMe();
      self.loadNextStrength();
    },
    getDateByMeasurement(measurementNumber) {
      return self.me.measurement_dates.get(measurementNumber);
    },
  }))
  .views((self) => ({
    get disableEnduranceSubmit() {
      if (!self.enduranceMinutes) return true;
      if (self.enduranceType === '') return true;
      if (self.enduranceType === 'annad' && self.enduranceOther === '') return true;
      return false;
    },
  }));

const StoreContext = React.createContext({});

export const StoreProvider = ({ children, store }) => {
  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
};

export const useStore = () => React.useContext(StoreContext);

const transformMeasurements = (measurements) => {
  let res = {};
  measurements.forEach((entry) => {
    entry.values.forEach(({ code, value }) => {
      if (res[code] === undefined) {
        res[code] = {};
      }
      res[code][entry.measurement] = value;
    });
  });
  return res;
};

const transformMeasurementDates = (measurements) => {
  let dates = {};
  measurements.forEach((entry) => {
    dates[entry.measurement] = format(parseISO(entry.date), 'MM.yy');
  });
  return dates;
};
