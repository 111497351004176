import React from 'react';

export const Checkmark = props => (
  <svg
    width="10"
    height="9"
    viewBox="0 0 10 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 4.99687L2.53125 7.17C2.7325 7.47063 3.14 7.55125 3.44062 7.35C3.50062 7.30938 3.55437 7.25937 3.59812 7.20187L8.5 1"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
