import React from 'react';
import { observer } from 'mobx-react-lite';
import { ThemeProvider } from 'styled-components';
import { IonApp } from '@ionic/react';

import { GlobalStyle } from './components/GlobalStyle';
import { theme } from './theme';
import { Routes } from './components/Routes';
import { SignIn } from './pages/SignIn';
import { Store, StoreProvider } from './models/Store';
import { InactiveUser } from './pages/InactiveUser';

const store = Store.create({});
window.store = store;

export const App = observer(() => {
  return (
    <ThemeProvider theme={theme}>
      <StoreProvider store={store}>
        <GlobalStyle />
        <IonApp>
          {store.token ?
            <>
              {store.me && store.me.active !== undefined && store.me.active !== null ?
                <>
                  {store.me.active === true ?
                    <Routes />
                    :
                    <InactiveUser
                    />
                  }
                </>
                :
                <Routes />
              }
            </>
            :
            <SignIn />
          }
        </IonApp>
      </StoreProvider>
    </ThemeProvider>
  );
});
