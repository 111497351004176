import { useContext } from 'react';
import { ThemeContext } from 'styled-components';

const sizes = {
  text14: '0.875rem',
  text16: '1rem',
  text18: '1.125rem',
  text20: '1.25rem',
  text22: '1.375rem',
  text24: '1.5rem',
  text30: '1.875rem',
  text35: '2.1875rem'
};

const weights = {
  normal: 400,
  semibold: 600,
  bold: 700
};

const spacing = {
  m4: '0.25rem',
  m8: '0.5rem',
  m12: '0.75rem',
  m16: '1rem',
  m24: '1.5rem',
  m28: '1.75rem',
  m32: '2rem',
  m40: '2.5rem'
};

const text = {
  normal35: {
    fontSize: sizes.text35,
    lineHeight: '2.625rem',
    fontWeight: weights.normal
  },
  semibold35: { fontSize: sizes.text35, lineHeight: '2.625rem', fontWeight: weights.semibold },
  bold35: { fontSize: sizes.text35, lineHeight: '2.625rem', fontWeight: weights.bold },
  normal30: {
    fontSize: sizes.text30,
    lineHeight: '2.25rem',
    fontWeight: weights.normal
  },
  semibold30: { fontSize: sizes.text30, lineHeight: '2.25rem', fontWeight: weights.semibold },
  bold30: { fontSize: sizes.text30, lineHeight: '2.25rem', fontWeight: weights.bold },
  semibold24: {
    fontSize: sizes.text24,
    lineHeight: '1.8125rem',
    fontWeight: weights.semibold
  },
  normal24: {
    fontSize: sizes.text24,
    lineHeight: '1.8125rem',
    fontWeight: weights.normal
  },
  bold24: { fontSize: sizes.text24, lineHeight: '1.8125rem', fontWeight: weights.bold },
  semibold22: {
    fontSize: sizes.text22,
    lineHeight: '1.625rem',
    fontWeight: weights.semibold
  },
  normal22: {
    fontSize: sizes.text22,
    lineHeight: '1.625rem',
    fontWeight: weights.normal
  },
  bold22: { fontSize: sizes.text22, lineHeight: '1.625rem', fontWeight: weights.bold },
  normal20: { fontSize: sizes.text20, lineHeight: '1.5rem', fontWeight: weights.normal },
  semibold20: {
    fontSize: sizes.text20,
    lineHeight: '1.5rem',
    fontWeight: weights.semibold
  },
  bold20: { fontSize: sizes.text20, lineHeight: '1.5rem', fontWeight: weights.bold },
  normal18: {
    fontSize: sizes.text18,
    lineHeight: '1.3125rem',
    fontWeight: weights.normal
  },
  semibold18: {
    fontSize: sizes.text18,
    lineHeight: '1.3125rem',
    fontWeight: weights.semibold
  },
  bold18: { fontSize: sizes.text18, lineHeight: '1.3125rem', fontWeight: weights.bold },
  normal16: {
    fontSize: sizes.text16,
    lineHeight: '1.1875rem',
    fontWeight: weights.normal
  },
  semibold16: {
    fontSize: sizes.text16,
    lineHeight: '1.1875rem',
    fontWeight: weights.semibold
  },
  normal14: {
    fontSize: sizes.text14,
    fontWeight: weights.normal,
    lineHeight: '1.0625rem'
  },
  semibold14: {
    fontSize: sizes.text14,
    fontWeight: weights.semibold,
    lineHeight: '1.0625rem'
  }
};

export const theme = {
  main: '#25A9BD',
  main80: '#646464',
  secondary: '#101010',

  headingGray: '#888',

  button: '#145875',
  buttonDisabled: 'rgba(20, 88, 118, 0.6)',

  border: '#A6A6A6',

  bold: 700,
  semibold: 500,

  white: '#fff',
  red: '#FF6161',
  redTrans: '#FFEBEB',
  orange: '#FCB244',
  orangeTrans: '#FFF5D2',
  purple: '#A292FD',
  purpleTrans: '#F5F3FF',
  blue: '#0D6DCB',
  blueTrans: '#DEEFFF',
  green: '#3CBD8F',
  greenTrans: '#E5FFF6',
  cyan: '#25A9BD',
  cyanTrans: '#E3FFFF',

  text,
  spacing,
  sizes
};

export const useTheme = () => useContext(ThemeContext);
