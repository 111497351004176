import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Page } from '../components/Page';
import { BackButton } from '../components/BackButton';
import { Box } from '../components/Box';
import { ProgressSteps } from '../components/ProgressSteps';
import { Text } from '../components/Text';
import { Card } from '../components/Card';
import { Button } from '../components/Button';
import hlauphjol from '../images/hlaup_hjol.png';

export const StrengthExerciseWarmup = observer(() => {
  return (
    <Page>
      <BackButton link="/exercise/strength" />
      <Box mt="32">
        <ProgressSteps count={3} current={0} />
      </Box>
      <Text type="semibold24" mt="32" children="Upphitun" />
      <Card mt="24" style={{ textAlign: 'center' }}>
        <Box column flex center>
          <img src={hlauphjol} alt="Göngubretti, hjól eða skíðatæki" />
          <Text mt="24" type="semibold30" children="Göngubretti, hjól eða skíðatæki" />
          <Text mt="12" type="normal22" children="10 - 15 mín" />
        </Box>
      </Card>
      <Text
        mt="24"
        style={{ textAlign: 'center' }}
        children={'Þegar upphituninni lýkur má ýta á "Áfram" og hefja aðalþátt.'}
      />
      <Link to="/exercise/strength/main">
        <Button mt="32" children="Áfram" />
      </Link>
    </Page>
  );
});
