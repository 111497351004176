import React from 'react';

export const Plus = props => (
  <svg width="25" height="25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M1 12.25h22.5H1z" fill="#145875" />
    <path
      d="M1 12.25h22.5"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M12.25 1v22.5V1z" fill="#145875" />
    <path
      d="M12.25 1v22.5"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
