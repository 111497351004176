import React from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

import { ChevronLeft } from '../icons/ChevronLeft';
import { CircleButton } from './CircleButton';
import { Box } from './Box';
import { Text } from './Text';

export const BackButton = observer(({ link = '/' }) => (
  <Link to={link}>
    <Box flex hCenter>
      <CircleButton>
        <ChevronLeft />
      </CircleButton>
      <Text type="semibold16" ml={16}>
        Til baka
      </Text>
    </Box>
  </Link>
));
