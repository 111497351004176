import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';

import { Page } from '../components/Page';
import { PageHeader } from '../components/PageHeader';
import { Box } from '../components/Box';
import { CalendarWeek } from '../components/CalendarWeek';
//import { CircleButton } from '../components/CircleButton';
//import { ChevronLeft } from '../icons/ChevronLeft';
//import { ChevronRight } from '../icons/ChevronRight';
//import { CalendarModel } from '../models/CalendarModel';

//const SomethingEntry = ({ day, date, active }) => {
//  return (
//    <div
//      style={{
//        //width: '2.75rem',
//        display: 'flex',
//        padding: '6px 8px',
//        flexDirection: 'column',
//        alignItems: 'center',
//        ...(active && {
//          backgroundColor: '#145875',
//          color: '#fff'
//        }),
//        borderRadius: '6px'
//      }}
//    >
//      <Text type="semibold14" color={active ? 'white' : 'main80'}>
//        {day}
//      </Text>
//      <div style={{ marginTop: '1rem' }}>{date}</div>
//    </div>
//  );
//};

//const Something = () => {
//  return (
//    <div
//      style={{
//        display: 'flex',
//        marginTop: 40,
//        justifyContent: 'space-between',
//        maxWidth: '25rem'
//      }}
//    >
//      <SomethingEntry day="Mán" date={1} />
//      <SomethingEntry day="Þri" date={2} />
//      <SomethingEntry day="Mið" date={3} />
//      <SomethingEntry active day="Fim" date={4} />
//      <SomethingEntry day="Fös" date={5} />
//      <SomethingEntry day="Lau" date={6} />
//      <SomethingEntry day="Sun" date={7} />
//    </div>
//  );
//};

const SelectedCalendarDayCard = styled.div(p => ({
  backgroundColor: p.theme.white,
  borderRadius: '10px',
  boxShadow: '0px 4px 30px rgba(154, 154, 154, 0.1)',
  padding: 20,
  minHeight: 800
}));

const SelectedCalendarDay = ({ day }) => {
  return (
    <Box mt="20px">
      <SelectedCalendarDayCard>
        <div>{day.toLocaleString()}</div>
      </SelectedCalendarDayCard>
    </Box>
  );
};

export const Calendar = observer(() => {
  //const [state, setState] = React.useState({});
  //const [model] = useState(() => CalendarModel.create());
  //window.mdl = model;
  return (
    <Page style={{ padding: '50px 0' }}>
      <div style={{ padding: '0 25px' }}>
        <PageHeader title="Dagatal" />
        <CalendarWeek date={new Date()} />
      </div>
      <SelectedCalendarDay day={new Date()} />
      {/*
      <CalendarToggle model={model} />
      <CalendarGrid model={model} />
      <Something />

      */}
    </Page>
  );
});

//const CalendarGrid = observer(({ model }) => {
//  return model.selectedView === 0 ? (
//    <CalendarWeek model={model} />
//  ) : (
//    <CalendarMonth model={model} />
//  );
//});

//const CalendarWeek = observer(({ model }) => {
//  return (
//    <div>
//      <CalendarGridHeaderWeek
//        currentMonth={model.weekView.month}
//        currentWeek={model.weekView.week}
//        onNextClick={model.weekView.nextWeek}
//        onPrevClick={model.weekView.prevWeek}
//      />
//    </div>
//  );
//});

//const CalendarMonth = observer(({ model }) => {
//  return (
//    <div>
//      <CalendarGridHeaderMonth model={model} />
//    </div>
//  );
//});

//const CalendarToggle = observer(({ model }) => {
//  const { setSelectedView, selectedView } = model;
//  return (
//    <ToggleContainer>
//      <ToggleItem>
//        <ToggleContent onClick={() => setSelectedView(0)} active={selectedView === 0}>
//          Vika
//        </ToggleContent>
//      </ToggleItem>
//      <ToggleItem>
//        <ToggleContent onClick={() => setSelectedView(1)} active={selectedView === 1}>
//          Mánuður
//        </ToggleContent>
//      </ToggleItem>
//    </ToggleContainer>
//  );
//});

//const CalendarGridHeaderMonth = observer(({ model }) => {
//  return (
//    <Box mt={40}>
//      <Box flex spaceBetween hCenter>
//        <div>
//          <Text color="main80" type="normal18">
//            {model.currentYear}
//          </Text>
//          <H2 bold>{model.month}</H2>
//        </div>
//        <CalendarForwardBack flex>
//          <CircleButton>
//            <ChevronLeft />
//          </CircleButton>
//          <CircleButton>
//            <ChevronRight />
//          </CircleButton>
//        </CalendarForwardBack>
//      </Box>
//    </Box>
//  );
//});

//const CalendarGridHeaderWeek = observer(props => {
//  const { currentMonth, currentWeek, onNextClick, onPrevClick } = props;
//  return (
//    <Box mt={40}>
//      <Box flex spaceBetween hCenter>
//        <div>
//          <Text type="normal18" color="main80">
//            {currentMonth}
//          </Text>
//          <H2 bold>Vika {currentWeek}</H2>
//        </div>
//        <CalendarForwardBack flex>
//          <CircleButton onClick={onPrevClick}>
//            <ChevronLeft />
//          </CircleButton>
//          <CircleButton onClick={onNextClick}>
//            <ChevronRight />
//          </CircleButton>
//        </CalendarForwardBack>
//      </Box>
//    </Box>
//  );
//});

//const CalendarForwardBack = styled(Box)({
//  '> button:first-child': {
//    marginRight: 10
//  }
//});
//
//const ToggleContainer = styled.div(p => ({
//  backgroundColor: p.theme.button,
//  borderRadius: 10,
//  width: '100%',
//  display: 'flex',
//  overflow: 'hidden',
//  padding: 8
//}));
//
//const ToggleItem = styled.div({
//  flex: 1
//});
//
//const ToggleContent = styled.div(p => ({
//  transition: 'all 0.4s',
//  color: p.active ? p.theme.secondary : p.theme.white,
//  backgroundColor: p.active ? p.theme.white : p.theme.button,
//  borderRadius: 7,
//  textAlign: 'center',
//  padding: 5,
//  fontWeight: p.theme.bold
//}));
