import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle(p => ({
  h1: { fontWeight: 400, fontSize: 35, margin: 0 },
  h2: { fontWeight: 400, fontSize: 24, margin: 0 },
  h3: { fontWeight: 400, fontSize: 22, margin: 0 },
  h4: { fontWeight: 400, fontSize: 20, margin: 0 },
  h5: { fontWeight: 400, fontSize: 18, margin: 0 },
  h6: {
    fontWeight: 400,
    fontSize: 14,
    margin: 0,
    textTransform: 'uppercase',
    color: p.theme.headingGray
  },
  '*': {
    outline: 'none !important'
  }
}));
