import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import { Text } from './Text';
import { useStore } from '../models/Store';
import { Box } from './Box';

export const KarvonenHR = observer(() => {
  const store = useStore();

  if (!store.me) return null;

  const {
    karvonen_50,
    karvonen_55,
    karvonen_60,
    karvonen_65,
    karvonen_70,
    karvonen_75,
    karvonen_80,
    karvonen_85,
    karvonen_90,
  } = store.me;
  if (
    karvonen_50 === null ||
    karvonen_55 === null ||
    karvonen_60 === null ||
    karvonen_65 === null ||
    karvonen_70 === null ||
    karvonen_75 === null ||
    karvonen_80 === null ||
    karvonen_85 === null ||
    karvonen_90 === null
  )
    return null;

  return (
    <ProgressBox>
      <Box mb="12" flex justifyContent="space-between">
        <Text type="semibold16">Karvonen</Text>
        <Text type="semibold16">Púls</Text>
      </Box>
      <KarvonenEntry color="#ffc000" perc="90%" value={karvonen_90} />
      <KarvonenEntry color="#548235" perc="85%" value={karvonen_85} />
      <KarvonenEntry color="#548235" perc="80%" value={karvonen_80} />
      <KarvonenEntry color="#a9d18e" perc="75%" value={karvonen_75} />
      <KarvonenEntry color="#a9d18e" perc="70%" value={karvonen_70} />
      <KarvonenEntry color="#2e75b6" perc="65%" value={karvonen_65} />
      <KarvonenEntry color="#2e75b6" perc="60%" value={karvonen_60} />
      <KarvonenEntry color="#9dc3e6" perc="55%" value={karvonen_55} />
      <KarvonenEntry color="#9dc3e6" perc="50%" value={karvonen_50} />
    </ProgressBox>
  );
});

const KarvonenEntry = ({ value, perc, color }) => (
  <Box mt="2" flex alignItems="center">
    <Box>{perc}</Box>
    <Box grow ml="16" mr="12">
      <ProgressBar ratio={perc} color={color} />
    </Box>
    <Box>{value}</Box>
  </Box>
);

const ProgressBox = styled.div(p => ({
  padding: '20px 15px',
  backgroundColor: '#fff',
  borderRadius: 10,
  flex: 1
}));

const ProgressIndicatorBackground = styled.div({
  height: 10,
  borderRadius: 10
});

const ProgressIndicator = styled.div(p => ({
  backgroundColor: p.color,
  width: `calc(${p.ratio} + 10%)`,
  height: 10,
  borderRadius: 10
}));

export const ProgressBar = ({ ratio, color }) => (
  <ProgressIndicatorBackground>
    <ProgressIndicator ratio={ratio} color={color} />
  </ProgressIndicatorBackground>
);
