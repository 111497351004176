import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { IonContent, IonPage } from '@ionic/react';

export const PageContainer = styled.div({
  padding: '50px 25px',
  backgroundColor: '#f4f4f4',
  minHeight: '100vh',
});

export const Page = observer((props) => {
  return (
    <IonPage>
      <IonContent>
        <PageContainer {...props} />
      </IonContent>
    </IonPage>
  );
});
