/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import './base.css';

ReactDOM.render(<App />, document.getElementById('root'));
