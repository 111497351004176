import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Page } from '../components/Page';
import { PageHeader } from '../components/PageHeader';
import { Text } from '../components/Text';
import { Card } from '../components/Card';
import { Box } from '../components/Box';
import { Button } from '../components/Button';
import { CalendarWeek } from '../components/CalendarWeek';
import { useStore } from '../models/Store';
import teygjur from '../images/teygjur.png';
import hlauphjol from '../images/hlaup_hjol.png';
import ganga from '../images/ganga.png';

export const EnduranceExerciseStart = observer(() => {
  const store = useStore();
  return (
    <Page>
      <PageHeader title="Þolþjálfun" backLink="/" />
      <CalendarWeek dateModel={store.exerciseDate} />
      <Link to="/exercise/endurance/main">
        <Button mt="32" children="Hefja æfingu" />
      </Link>
      <Card condensed mt="16">
        <Box flex alignItems="center" justifyContent="space-between">
          <div>
            <Text type="semibold22" children="Upphitun" />
            <Text type="normal18" mt="16" color="main80" children="10 - 15 mín" />
          </div>
          <img src={teygjur} height="110" alt="teygjur" />
        </Box>
      </Card>

      <Card condensed mt="16">
        <Box flex alignItems="center" justifyContent="space-between">
          <div>
            <Text type="semibold22" children="Aðalþáttur" />
            <Text type="normal18" mt="16" color="main80" children="20 - 60 mín" />
          </div>
          <img src={hlauphjol} height="110" alt="hlaup, hjól" />
        </Box>
      </Card>

      <Card condensed mt="16">
        <Box flex alignItems="center" justifyContent="space-between">
          <div>
            <Text type="semibold22" children="Niðurlag" />
            <Text type="normal18" mt="16" color="main80" children="10 - 15 mín" />
          </div>
          <img src={ganga} height="110" alt="ganga" />
        </Box>
      </Card>
    </Page>
  );
});
