import { toISformatting } from './helpers/numbers';

const numberToBoolString = (numb) => {
  if (Number(numb)) {
    return 'Já';
  }
  return 'Nei';
};

export const mapper = new Map(
  Object.entries({
    BP_Meds: {
      section: 'Líkami',
      subSection: 'Lyf',
      title: 'Blóðþrýstingslyf',
      render: numberToBoolString,
    },
    Glucose_Meds: {
      section: 'Líkami',
      subSection: 'Lyf',
      title: 'Sykursýkislyf',
      render: numberToBoolString,
    },
    Triglycerides_Meds: {
      section: 'Líkami',
      subSection: 'Lyf',
      title: 'Lyf vegna blóðfitu',
      render: numberToBoolString,
    },
    Cholesterol_Meds: {
      section: 'Líkami',
      subSection: 'Lyf',
      title: 'Lyf vegna kólesteróls',
      render: numberToBoolString,
    },
    Num_Meds: {
      section: 'Líkami',
      subSection: 'Lyf',
      title: 'Fjöldi lyfja',
      render: (val) => toISformatting(val, 0),
    },
    stretches_title: {
      title: 'Liðleiki (cm)',
      section: 'Líkami',
      subSection: '',
    },
    Tey_FH: {
      title: 'Setið og teygt fram yfir hægri; cm',
      section: 'Teygjur',
      subSection: '',
      render: (val) => toISformatting(val, 0),
    },
    Tey_FV: {
      title: 'Setið og teygt fram yfir vinstri; cm',
      section: 'Teygjur',
      subSection: '',
      render: (val) => toISformatting(val, 0),
    },
    Tey_AH: {
      title: 'Teygt aftur fyrir bak, hægri-vinstri; cm',
      section: 'Teygjur',
      subSection: '',
      render: (val) => toISformatting(val, 0),
    },
    Tey_AV: {
      title: 'Teygt aftur fyrir bak, vinstri-hægri; cm',
      section: 'Teygjur',
      subSection: '',
      render: (val) => toISformatting(val, 0),
    },
    SBP: {
      title: 'Blóðþrýstingur (SBP; efri mörk); mmHg',
      section: 'Líkami',
      subSection: '',
      render: (val) => toISformatting(val, 0),
    },
    DBP: {
      title: 'Blóðþrýstingur (DBP; neðri mörk); mmHg',
      section: 'Líkami',
      subSection: '',
      render: (val) => toISformatting(val, 0),
    },
    HRR: {
      title: 'Hvíldarpúls (HRR); slög/mín',
      section: 'Líkami',
      subSection: '',
      render: (val) => toISformatting(val, 0),
    },
    Heigh: {
      title: 'Hæð; cm',
      section: 'Líkami',
      subSection: '',
      render: (val) => toISformatting(val, 1),
    },
    Weight: {
      title: 'Þyngd; kg',
      section: 'Líkami',
      subSection: '',
    },
    BMI: {
      title: 'Líkamsþyngdarstuðull (BMI); þyngd/hæð^2',
      section: 'Líkami',
      subSection: '',
    },
    Girth: {
      title: 'Mittismál; cm',
      section: 'Líkami',
      subSection: '',
      render: (val) => toISformatting(val, 1),
    },
    Hibs: {
      title: 'Mjaðmamál; cm',
      section: 'Líkami',
      subSection: '',
      render: (val) => toISformatting(val, 1),
    },
    WHR: {
      title: 'Mittis- og mjaðmamálshlutfall (WHR)',
      section: 'Líkami',
      subSection: '',
    },
    body_title: {
      section: 'Líkami',
      subSection: '',
      title: 'Líkamssamsetning',
    },
    TBW: {
      title: 'Vökvamagn; L',
      section: 'Líkami',
      subSection: '',
    },
    MMa: {
      title: 'Vöðvamassi; kg',
      section: 'Líkami',
      subSection: '',
    },
    BFM: {
      title: 'Fitumassi; kg',
      section: 'Líkami',
      subSection: '',
    },
    FFM: {
      title: 'Fitufrír massi; kg',
      section: 'Líkami',
      subSection: '',
    },
    PBF: {
      title: 'Fituprósenta; %',
      section: 'Líkami',
      subSection: '',
    },
    strength_title: {
      title: 'Styrkur (vöðvaþol)',
      section: '',
      subSection: '',
    },
    Stóll_30s: {
      title: 'Staðið upp af stól í 30 sek; fjöldi skipta',
      section: 'Mæling 7-8',
      subSection: '',
      render: (val) => toISformatting(val, 0),
    },
    Armb_H30s: {
      title: 'Armbeygjur í 30 sek; fjöldi skipta hægri',
      section: 'Mæling 7-8',
      subSection: '',
      render: (val) => toISformatting(val, 0),
    },
    Armb_V30s: {
      title: 'Armbeygjur í 30 sek; fjöldi skipta vinstri',
      section: 'Mæling 7-8',
      subSection: '',
      render: (val) => toISformatting(val, 0),
    },
    Stand_Go: {
      title: 'Hreyfijafnvægi; 8-feta hreyfijafnvægi; sek',
      section: '8-feet up-and-go test',
      subSection: '',
    },
    SPPB_Bal_parallel_sek: {
      title: 'SPPB: Jafnvægi, samhliða lokið; sek',
      section: 'SPPB',
      subSection: '',
    },
    SPPB_Bal_halfparallel_sek: {
      title: 'SPPB: Jafnvægi, 1/2 samhliða lokið; sek',
      section: 'SPPB',
      subSection: '',
    },
    SPPB_Bal_heeltoe_sek: {
      title: 'SPPB: Jafnvægi, hæll-tá lokið; sek',
      section: 'SPPB',
      subSection: '',
    },
    SPPB_Bal_stig: {
      title: 'SPPB: Jafnvægi; stig',
      section: 'SPPB',
      subSection: '',
      render: (val) => toISformatting(val, 0),
    },
    SPPB_4m_besti_timi: {
      title: 'SPPB: 4m ganga; Besti tími; sek',
      section: 'SPPB',
      subSection: '',
    },
    SPPB_4m_stig: {
      title: 'SPPB: 4m ganga; stig',
      section: 'SPPB',
      subSection: '',
      render: (val) => toISformatting(val, 0),
    },
    SPPB_Chair_assistance: {
      section: 'SPPB',
      subSection: 'Standa upp úr stól',
      title: 'Án aðstoðar',
      render: (val) => (val === 'no' ? 'Nei' : 'Já'),
    },
    SPPB_Chair: {
      title: 'SPPB: Staðið upp af stól 5 sinnum; sek',
      section: 'SPPB',
      subSection: '',
    },
    SPPB_Chair_stig: {
      title: 'SPPB: Staðið upp af stól 5 sinnum; stig',
      section: 'SPPB',
      subSection: '',
      render: (val) => toISformatting(val, 0),
    },
    SPPB_STIG: {
      title: 'SPPB-hreyfifrænipróf: Heildarniðurstaða; stig',
      section: 'SPPB',
      subSection: '',
      render: (val) => toISformatting(val, 0),
    },
    HaSty_H: {
      title: 'Handstyrkur hægri handar; kg',
      section: 'Handstyrkur',
      subSection: '',
      render: (val) => toISformatting(val, 0),
    },
    HaSty_V: {
      title: 'Handstyrkur vinstri handar; kg',
      section: 'Handstyrkur',
      subSection: '',
      render: (val) => toISformatting(val, 0),
    },
    HaStyBadar: {
      title: 'Handstyrkur (vöðvaafl); summa beggja handa; kg',
      section: 'Handstyrkur',
      subSection: '',
      render: (val) => toISformatting(val, 0),
    },
    Six_Minm: {
      title: 'Þol; 6 mínútuna göngupróf (6MW); heildarvegalengd; m',
      section: '6. mínútna ganga',
      subSection: '',
      render: (val) => toISformatting(val, 0),
    },
    HRe6MW: {
      title: '6MW; Lokapúls eftir 6 mínútur; slög/mín',
      section: '6. mínútna ganga',
      subSection: '',
      render: (val) => toISformatting(val, 0),
    },
    HR7min6MW: {
      title: '6MW; Púls eftir 1 mín hvíld (7. mín); slög/mín)',
      section: '6. mínútna ganga',
      subSection: '',
      render: (val) => toISformatting(val, 0),
    },
    Life_Quality: {
      title: 'Heilsa og lífsgæði; stig frá 0-100; stig',
      section: 'Heilsa og lífsgæði; Stig 0-100',
      subSection: '',
      render: (val) => toISformatting(val, 0),
    },
    Dagleg_Hreyfing_Medaltal: {
      title: 'Dagleg hreyfing; mínútur á dag',
      section: 'Dagleg hreyfing; mínútur á dag',
      subSection: '',
    },
    Styrktarthjalfun_Samtals: {
      title: 'Styrktarþjálfun; skipti í viku',
      section: 'Styrktarþjálfun; skipti í viku',
      subSection: '',
      render: (val) => toISformatting(val, 1),
    },
  })
);

const getSectionKeys = () => {
  let res = new Map();
  mapper.forEach((obj, key) => {
    const sect = obj.section;
    if (res.has(sect)) {
      res.get(sect).push(key);
    } else {
      res.set(sect, [key]);
    }
  });
  res.delete('');
  return Array.from(res.entries());
};

export const sectionKeys = getSectionKeys();
