import React from 'react';
import { observer } from 'mobx-react-lite';
import { Page } from '../components/Page';
import { PageHeader } from '../components/PageHeader';
import { KarvonenHR } from '../components/KarvonenHR';

export const Karvonen = observer(() => {
  return (
    <Page>
      <PageHeader title="Karvonen" backLink="/" />
      <KarvonenHR />
    </Page>
  );
});
