import React from 'react';
import styled from 'styled-components/macro';
import { Box } from './Box';

export const Card = styled(({ condensed, accentColor, bgColor, ...rest }) => <Box {...rest} />)(
  p => ({
    position: 'relative',
    padding: p.condensed ? '20px 24px' : '24px 48px',
    borderRadius: '10px',
    borderLeft: '9px solid ' + p.theme[p.accentColor],
    backgroundColor: p.bgColor ? p.theme[p.bgColor] || p.bgColor : 'white',
    color: p.theme.janusSecondary
  })
);
