import React, { useState } from 'react';
import { IonModal, IonContent } from '@ionic/react';
import styled from 'styled-components/macro';

import { CloseIcon } from '../icons/CloseIcon';
import { CircleButton } from './CircleButton';
import { Text, H3, FormLabel } from './Text';
import { Switch } from './Switch';
import { Box } from './Box';
import { Input } from './Input';
import { HorizontalLine } from './HorizontalLine';
import { Button } from './Button';

export const SettingsModal = ({ onClose, open }) => {
  const [nots, setNots] = useState(false);
  const [bp, setBp] = useState(true);
  return (
    <>
      <IonModal isOpen={open} cssClass="modal" onDidDismiss={onClose}>
        <IonContent>
          <ModalContent>
            <ModalHeader>
              <Text type="semibold16">Loka</Text>
              <Box ml="16">
                <CircleButton onClick={onClose}>
                  <CloseIcon />
                </CircleButton>
              </Box>
            </ModalHeader>

            <Box mt="40" mb="24">
              <H3 semibold>Áminningar</H3>
            </Box>
            <Box flex spaceBetween hCenter>
              <Text>Það er kveikt á áminningum</Text>
              <Switch value={nots} onToggle={e => setNots(e.target.checked)} />
            </Box>
            <Box mt="24">
              <FormLabel>Áminning fyrir æfingar</FormLabel>
              <Input placeholder="Stilla áminningu fyrir æfingar" />
            </Box>
            <Box mt="24">
              <FormLabel>Áminning fyrir fyrirlestra</FormLabel>
              <Input placeholder="Stilla áminningu fyrir fyrirlestra" />
            </Box>

            <HorizontalLine />

            <Box mb="24">
              <H3 semibold>Blóðþrýstingslyf</H3>
            </Box>
            <Box flex spaceBetween hCenter>
              <Text>Ég er ekki á blóðþrýstingslyfjum</Text>
              <Switch value={bp} onToggle={e => setBp(e.target.checked)} />
            </Box>

            <HorizontalLine />

            <Box mb="24">
              <H3 semibold>Breyta lykilorði</H3>
            </Box>
            <Box mt="24">
              <FormLabel>Núverandi lykilorð</FormLabel>
              <Input placeholder="Hvað er núverandi lykilorðið þitt?" />
            </Box>
            <Box mt="24">
              <FormLabel>Nýtt lykilorð</FormLabel>
              <Input placeholder="Nýja lykilorðið þitt" />
            </Box>

            <HorizontalLine />

            <Box mb="24">
              <H3 semibold>Persónuupplýsingar</H3>
            </Box>
            <Box mt="24">
              <FormLabel>Netfang</FormLabel>
              <Input value="siggirh@gmail.com" onChange={() => console.log('TODO!!')} />
            </Box>
            <Box mt="24">
              <FormLabel>Símanúmer</FormLabel>
              <Input value="848-5119" onChange={() => console.log('TODO!!')} />
            </Box>
            <Box mt="24">
              <Button>Vista</Button>
            </Box>
          </ModalContent>
        </IonContent>
      </IonModal>
    </>
  );
};

const ModalContent = styled.div({
  padding: 20
});

const ModalHeader = styled.div({
  width: 'auto',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center'
});
