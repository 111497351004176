import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';

export const Switch = observer(({ onToggle, value, disabled, readOnly }) => {
  return (
    <label>
      <SliderBox checked={value} tabIndex="0">
        <input
          style={{ display: 'none' }}
          type="checkbox"
          disabled={disabled}
          checked={value}
          readOnly={readOnly}
          onChange={onToggle}
        />
        <SliderBall checked={value} />
      </SliderBox>
    </label>
  );
});

const SliderBox = styled.div((p) => ({
  width: 60,
  height: 29,
  backgroundColor: p.checked ? p.theme.main : p.theme.main80,
  borderRadius: 14.5,
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  transition: 'background-color 0.4s',
}));

const SliderBall = styled.div((p) => ({
  height: 25,
  width: 25,
  borderRadius: '50%',
  backgroundColor: p.theme.white,
  marginLeft: 2,
  marginRight: 2,
  left: 0,
  position: 'absolute',
  transition: 'transform 0.4s',
  ...(p.checked && { transform: 'translateX(30px)' }),
}));
