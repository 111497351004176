import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import { Box } from './Box';
import { useStore } from '../models/Store';
import { IonAlert } from '@ionic/react';

const StyledButton = styled(Box)((p) => ({
  position: "fixed",
  top: 50,
  right: 25,
  color: p.color ? p.theme[p.color] : p.theme.white,
  padding: 16,
  fontSize: 18,
  textAlign: 'center',
  backgroundColor: p.disabled ? p.theme.buttonDisabled : p.bg ? p.theme[p.bg] : p.theme.button,
  borderRadius: 10,
}));

export const LogoutButton = observer((props) => {
  const { disabled } = props;

  const [isOpen, setIsOpen] = useState(false);

  const store = useStore();
  return (
    <>

      <StyledButton
        as="button"
        onClick={() => setIsOpen(true)}
        disabled={disabled}
        type="button"
      >Útskráning</StyledButton>

      <IonAlert
        isOpen={isOpen}
        trigger="present-alert"
        header="Viltu skrá þig út?"
        buttons={[
          {
            text: 'Hætta við',
            role: 'cancel',
            handler: () => {
              setIsOpen(false);
            },
          },
          {
            text: 'Útskrá',
            role: 'confirm',
            handler: () => {
              store.logOut();
            },
          },
        ]}

      ></IonAlert>
    </>
  );
});
