import styled from 'styled-components/macro';

export const CircleButton = styled.button(p => ({
  width: p.small ? 25 : 45,
  height: p.small ? 25 : 45,
  borderRadius: p.small ? 25 / 2 : 45 / 2,
  backgroundColor: p.bgColor ? p.theme[p.bgColor] || p.bgColor : p.theme.white,
  boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.1)',
  color: p.theme[p.color || 'secondary'],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));
