import React from 'react';
import { observer, useLocalStore } from 'mobx-react-lite';
import { IonModal, IonContent, IonToast } from '@ionic/react';
import styled from 'styled-components/macro';
import { isSameDay, format } from 'date-fns';

import { api } from '../api';
import { useCalendarWeekView } from '../hooks/useCalendarWeekView';
import { useEnduranceOptions } from '../hooks/useEnduranceOptions';
import { CloseIcon } from '../icons/CloseIcon';
import { CircleButton } from './CircleButton';
import { Text } from './Text';
import { Box } from './Box';
import { Button } from './Button';
import { CalendarWeek } from './CalendarWeek';
import { Select } from './Select';
import { Input } from './Input';
import { NumberInput } from './NumberInput';
import { useStore } from '../models/Store';

export const EditEnduranceExercise = observer(({ onClose, item, onSuccess, onRemoveSuccess }) => {
  const store = useStore();
  const dateModel = useCalendarWeekView(item.date);
  const enduranceOptions = useEnduranceOptions();

  const state = useLocalStore(() => {
    const isOther = enduranceOptions.find((it) => it.value === item.endurance_type) === undefined;
    return {
      selectedType: isOther ? 'annad' : item.endurance_type,
      otherType: isOther ? item.endurance_type : '',
      minutes: item.minutes,
      showSuccess: false,
      submitting: false,
      setSubmitting(val) {
        state.submitting = val;
      },
      selectType(val) {
        if (val === 'annad') {
          state.otherType = '';
        }
        state.selectedType = val;
      },
      setOtherType(val) {
        state.otherType = val;
      },
      increaseMinutes() {
        state.minutes += 5;
      },
      decreaseMinutes() {
        state.minutes = Math.max(0, state.minutes - 5);
      },
      setMinutes(mins) {
        state.minutes = Number(mins);
      },
      get dirty() {
        const enduranceDiff = state.otherType
          ? state.otherType !== item.endurance_type
          : state.selectedType !== item.endurance_type;

        return (
          enduranceDiff ||
          state.minutes !== item.minutes ||
          !isSameDay(dateModel.selected, new Date(item.date))
        );
      },
      async remove() {
        state.setSubmitting(true);
        const res = await api.remove_endurance_exercise(item.id);
        if (!res.error) {
          store.removeEnduranceExercise(item.id);
          onRemoveSuccess();
        } else {
          state.setSubmitting(false);
          state.setShowError(true);
        }
      },
      async save() {
        if (!state.dirty) return;
        state.setSubmitting(true);
        const data = {
          endurance_type: state.selectedType === 'annad' ? state.otherType : state.selectedType,
          minutes: state.minutes,
          date: format(dateModel.selected, 'yyyy-MM-dd'),
        };
        const res = await api.update_endurance_exercise(item.id, data);
        if (res.error) {
          state.setSubmitting(false);
          state.setShowError(true);
        } else {
          store.updateEnduranceExercise(item.id, { ...data, id: item.id });
          onSuccess();
        }
      },
      setShowError(val) {
        state.showError = val;
      },
    };
  });

  return (
    <>
      <IonModal isOpen cssClass="modal" onDidDismiss={onClose}>
        <IonContent>
          <ModalContent>
            <IonToast
              isOpen={state.showError}
              header="Aðgerð mistókst"
              message="Vinsamlegast reyndu aftur."
              duration={4000}
              mode="md"
              color="danger"
              onDidDismiss={() => state.setShowError(false)}
            />
            <ModalHeader>
              <Text type="semibold16">Loka</Text>
              <Box ml="16">
                <CircleButton onClick={onClose}>
                  <CloseIcon />
                </CircleButton>
              </Box>
            </ModalHeader>
            <CalendarWeek dateModel={dateModel} />
            <Box mt="32" w="100%">
              <Select
                options={enduranceOptions}
                value={state.selectedType}
                onSelect={state.selectType}
              />
            </Box>
            {state.selectedType === 'annad' && (
              <Box mt="12">
                <Input
                  placeholder="Hvernig æfing varð fyrir valinu?"
                  value={state.otherType}
                  onChange={state.setOtherType}
                />
              </Box>
            )}
            <Box mt="8">
              <NumberInput
                onIncrease={state.increaseMinutes}
                onDecrease={state.decreaseMinutes}
                onChange={state.setMinutes}
                unit="mín"
                value={state.minutes}
              />
            </Box>
            <Button mt="16" onClick={state.save} disabled={!state.dirty || state.submitting}>
              Vista
            </Button>
            <Button bg="red" mt="16" onClick={state.remove} disabled={state.submitting}>
              Eyða æfingu
            </Button>
          </ModalContent>
        </IonContent>
      </IonModal>
    </>
  );
});

const ModalContent = styled.div({
  padding: 20,
});

const ModalHeader = styled.div({
  width: 'auto',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
});
