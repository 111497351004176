import { useMemo } from 'react';

export const useEnduranceOptions = () => {
  return useMemo(
    () => [
      { display: 'Veldu aðalþátt', value: '' },
      { display: 'Ganga', value: 'Ganga' },
      { display: 'Hjóla', value: 'Hjóla' },
      { display: 'Synda', value: 'Synda' },
      { display: 'Dansa', value: 'Dansa' },
      { display: 'Golf', value: 'Golf' },
      { display: 'Sundleikfimi', value: 'Sundleikfimi' },
      { display: 'Annað', value: 'Annað' },
    ],
    []
  );
};
