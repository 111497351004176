import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import banner from '../images/signin-banner.svg';
import { Button } from '../components/Button';
import { Page } from '../components/Page';
import { Input } from '../components/Input';
import { Box } from '../components/Box';
import { H1, FormLabel, Text, FormError } from '../components/Text';
import { JanusBigLogo } from '../icons/JanusBigLogo';
import { api } from '../api';
import { tryTranslateError } from '../locale/errors';
import { useStore } from '../models/Store';

export const SignIn = observer(() => {
  const store = useStore();
  const [{ username, password, status, errors }, setState] = React.useState({
    username: '',
    password: '',
    status: 'initial',
    errors: {},
  });

  const setPassword = (val) => {
    setState((old) => ({
      ...old,
      password: val,
      errors: { ...old.errors, password: undefined },
    }));
  };
  const setUsername = (val) => {
    setState((old) => ({
      ...old,
      username: val,
      errors: { ...old.errors, username: undefined },
    }));
  };

  const signIn = async () => {
    setState((old) => ({ ...old, status: 'submitting', errors: {} }));
    const res = await api.authenticate(username, password);
    if (res.error) {
      setState((old) => ({ ...old, status: 'error', errors: res.data }));
    } else {
      setState((old) => ({ ...old, status: 'initial' }))
      store.setToken(res.data.token);
    }
  };

  return (
    <Page style={{ padding: 0 }}>
      <Box flex flexDirection="column" style={{ minHeight: '100vh' }}>
        <Box padding="24" style={{ width: '100%' }}>
          <Box flex vCenter>
            <JanusBigLogo />
          </Box>
          <Box flex center mt="24">
            <H1>Innskráning</H1>
          </Box>

          {errors.non_field_errors && (
            <Box mt="40" mb="16">
              <Text color="red">{errors.non_field_errors.map(tryTranslateError).join('\n')}</Text>
            </Box>
          )}

          <Box mt={errors.non_field_errors ? '24' : '40'}>
            <FormLabel>Notandanafn</FormLabel>
            <Input
              disabled={status === 'submitting'}
              placeholder="Notandanafnið þitt"
              onChange={setUsername}
              value={username}
              error={errors.username}
            />
          </Box>
          {errors.username && (
            <FormError>{errors.username.map(tryTranslateError).join(', ')}</FormError>
          )}

          <Box mt="24">
            <FormLabel>Lykilorð</FormLabel>
            <Input
              type="password"
              disabled={status === 'submitting'}
              placeholder="Lykilorðið þitt"
              onChange={setPassword}
              value={password}
              error={errors.password}
            />
            {errors.password && (
              <FormError>{errors.password.map(tryTranslateError).join(', ')}</FormError>
            )}
          </Box>

          <Box mt="40">
            <Button disabled={status === 'submitting'} onClick={signIn}>
              {status === 'submitting' ? 'Skrái inn...' : 'Staðfesta'}
            </Button>
          </Box>
        </Box>
        <img src={banner} style={{ marginBottom: -15, width: '100%' }} alt="people exercise" />
        <FillBackground />
      </Box>
    </Page>
  );
});

const FillBackground = styled.div((p) => ({
  backgroundColor: p.theme.main,
  width: '100%',
  flex: 1,
  minHeight: 20,
}));
