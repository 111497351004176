import React from 'react';

export const Minus = props => (
  <svg width="25" height="2" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1 1h22.5"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
