import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { useStore } from '../models/Store';
import { Page } from '../components/Page';
import { BackButton } from '../components/BackButton';
import { Card } from '../components/Card';
import { Text } from '../components/Text';
import { RedAccentLine } from '../components/RedAccentLine';
import { Box } from '../components/Box';
import { Button } from '../components/Button';
import { SplitLine } from '../components/SplitLine';
import { NumberInput } from '../components/NumberInput';
import { EquipmentImage, numberMap } from '../components/EquipmentImage';

export const StrengthExerciseMainDetail = observer(() => {
  const store = useStore();
  const { id } = useParams();
  const history = useHistory();
  if (!store.nextStrength) return null;
  const set = store.nextStrength.sets.find((set) => set.id === Number(id));
  if (!set) return null;
  const override = store.nextStrength.overrides.find(o => o.equipment === set.equipment.equipment_no);
  const equipment_vimeo_ids = store.nextStrength.equipment_vimeo_ids
  const hasImage = numberMap[set.equipment.equipment_no] !== undefined;

  let vimeo_id = null;

  if (equipment_vimeo_ids && set.equipment && equipment_vimeo_ids.get(set.equipment.equipment_no) !== null) {
    vimeo_id = equipment_vimeo_ids.get(set.equipment.equipment_no);
  }
  if (override && override.vimeo_id) {
    vimeo_id = override.vimeo_id;
  }


  return (
    <Page>
      <BackButton link="/exercise/strength/main" />
      <Text type="semibold24" mt="40" children="Þyngd" />
      {set.last_weight !== null && (
        <Card condensed bgColor="#EAEAEA" mt="24">
          <Box flex alignItems="center" justifyContent="space-between">
            <Text children="Þyngd á seinustu æfingu:" />
            <Text type="bold18" children={set.last_weight || '?'} />
          </Box>
        </Card>
      )}

      <NumberInput
        onDecrease={set.decreaseWeight}
        onIncrease={set.increaseWeight}
        onChange={set.setWeight}
        value={set.newWeight === '' ? '' : set.newWeight}
        unit="kg"
      />

      <Button
        mt="32"
        children="Staðfesta"
        disabled={Number(set.newWeight) === 0}
        onClick={() => {
          set.setDone();
          history.push('/exercise/strength/main');
        }}
      />
      <Card mt="40" condensed>
        <Text children={`Stöð ${set.equipment.equipment_no}`} />
        <Text type="semibold35" mt="12" children={set.equipment.name} />
        <RedAccentLine />

        <Box mt="32" flex justifyContent="space-between">
          <Box flex center column w="50%">
            <Text type="bold22" children={set.repetitions} />
            <Text mt="8" color="main80" children="Endurtekning" />
          </Box>
          <Box flex center column w="50%">
            <Text type="bold22" children={set.percentage} />
            <Text mt="8" color="main80" children="Ákefð" />
          </Box>
        </Box>

        {hasImage && (
          <>
            <Box mt="24" mb="24">
              <SplitLine />
            </Box>

            <Box flex justifyContent="center">
              <EquipmentImage number={set.equipment.equipment_no} />
            </Box>
          </>
        )}

        <Box mt="24" mb="24">
          <SplitLine />
        </Box>

        {override && override.text ?
          <Text style={{ textAlign: 'center' }}>
            {override.text}
          </Text>
          :
          <Text style={{ textAlign: 'center' }}>
            Lyfta þyngd ákveðið í <strong>2 sek</strong> en færið rólega til baka á um{' '}
            <strong>4 sek</strong>
          </Text>
        }

        {vimeo_id &&
          <Box mt="24" mb="24" style={{ textAlign: 'center' }}>
            <div style={{ padding: "75% 0 0 0", position: "relative" }}><iframe title="Video" src={`https://player.vimeo.com/video/${vimeo_id}?title=0&byline=0&portrait=0`} style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
          </Box>
        }
      </Card>
    </Page>
  );
});
