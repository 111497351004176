import React from 'react';

export const ChevronLeft = () => (
  <svg width="10" height="18" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.79166 14.5625L1.53832 8.30917C1.36741 8.13883 1.36741 7.86175 1.53774 7.69142L1.53833 7.69083L7.79166 1.4375"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
