import React from 'react';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';

import { Card } from '../components/Card';
import { Box } from '../components/Box';
import { CircleButton } from '../components/CircleButton';
import { Minus } from '../icons/Minus';
import { Text } from '../components/Text';
import { Plus } from '../icons/Plus';

export const NumberInput = observer(({ onDecrease, onIncrease, value, onChange, unit }) => {
  return (
    <Card>
      <Box flex flexDirection="column" alignItems="center" justifyContent="space-between">
        <CircleButton bgColor="button" color="white" onClick={onIncrease}>
          <Plus />
        </CircleButton>
        <WeightContainer>
          <input
            type="number"
            value={value}
            onChange={(e) => onChange(e.target.value)}
            onFocus={(e) => e.target.select()}
          />
          <Text ml="8" mb="8" color="main80" children={unit} />
        </WeightContainer>
        <CircleButton bgColor="red" color="white" onClick={onDecrease}>
          <Minus />
        </CircleButton>
      </Box>
    </Card>
  );
});
const WeightContainer = styled.div((p) => ({
  ...p.theme.text.bold35,
  border: '1px solid rgba(166,166,166, 0.3)',
  borderRadius: '10px',
  padding: '1rem 0.5rem',
  margin: '0.5rem 0',
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'center',
  maxWidth: 200,
  input: {
    marginLeft: 2,
    width: '100%',
    border: 'none',
    outline: 'none',
    textAlign: 'right',
  },
}));
