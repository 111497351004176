import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Page } from '../components/Page';
import { PageHeader } from '../components/PageHeader';
import { Text } from '../components/Text';
import { Card } from '../components/Card';
import { Box } from '../components/Box';
import { Button } from '../components/Button';
import { CalendarWeek } from '../components/CalendarWeek';
import { IonFooter, IonToolbar } from '@ionic/react';
import { useStore } from '../models/Store';
import ganga from '../images/ganga.png';
import hlauphjol from '../images/hlaup_hjol.png';
import lyftingar from '../images/lyftingar.png';

export const StrengthExerciseStart = observer(() => {
  const store = useStore();

  if (!store.nextStrength) {
    return <Redirect to="/" />;
  }

  return (
    <Page>
      <PageHeader title="Styrktarþjálfun" backLink="/" />
      <CalendarWeek
        dateModel={store.exerciseDate}
        notBefore={store.nextStrength.last_exercise_date}
      />
      <Link to="/exercise/strength/warmup">
        <Button mt="32" children="Hefja æfingu" onClick={store.startStrengthExercise} />
      </Link>
      <Text type="semibold24" children="Upphitun" mt="32" />
      <Card condensed mt="16">
        <Box flex alignItems="center" justifyContent="space-between">
          <div>
            <Text type="semibold22" children="Göngubretti, hjól eða skíðatæki" />
            <Text type="normal18" mt="16" color="main80" children="10 - 15 mín" />
          </div>
          <img src={hlauphjol} height="100" alt="hlaup og hjól" />
        </Box>
      </Card>

      <Text type="semibold24" mt="32" children="Aðalþáttur" />
      <Card condensed mt="16">
        <Box flex alignItems="center" justifyContent="space-between">
          <div>
            <Text type="semibold22" children="Styrktarþjálfun" />
            <Text type="normal18" mt="8" color="main80" children="10 endurtekningar" />
          </div>
          <img src={lyftingar} height="100" alt="lyftingar" />
        </Box>
      </Card>

      <Text type="semibold24" mt="32" children="Niðurlag" />
      <Card condensed mt="16">
        <Box flex alignItems="center" justifyContent="space-between">
          <div>
            <Text type="semibold22" children="Ganga eða hjóla rólega" />
            <Text type="normal18" mt="16" color="main80" children="10 - 15 mín" />
            <Text type="normal18" mt="4" color="main80" children="Liðkun, teygjur og slökun" />
          </div>
          <img src={ganga} height="100" alt="ganga" />
        </Box>
      </Card>
    </Page>
  );
});
