import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Box } from './Box';

const StyledText = styled(Box)((p) => ({
  color: p.color ? p.theme[p.color] : p.theme.janusSecondary,
  display: p.block ? 'block' : 'inline-block',
  ...p.theme.text[p.type],
  ...(p.caps && { textTransform: 'uppercase' }),
}));

export const Text = ({
  color = 'secondary',
  type = 'normal18',
  block = true,
  caps = false,
  as = 'span',
  ...rest
}) => <StyledText color={color} type={type} block={block} caps={caps} as={as} {...rest} />;

export const FormLabel = observer(({ children, ...rest }) => (
  <Text mb="12" type="semibold16" {...rest}>
    {children}
  </Text>
));

export const FormError = observer(({ children, ...rest }) => (
  <Text mt="4" type="normal14" color="red" {...rest}>
    {children}
  </Text>
));

export const H1 = observer((props) => (
  <Text as="h1" {...props} type={props.bold ? 'bold35' : 'normal35'} />
));
export const H2 = observer((props) => (
  <Text as="h2" {...props} type={props.bold ? 'bold24' : 'normal24'} />
));
export const H3 = observer((props) => (
  <Text as="h3" {...props} type={props.bold ? 'bold22' : 'normal22'} />
));
export const P = observer((props) => <Text as="p" {...props} />);
