import React from 'react';
import { Page } from '../components/Page';
import { PageHeader } from '../components/PageHeader';

export const Statistics = () => {
  return (
    <Page>
      <PageHeader title="Tölfræði" />
    </Page>
  );
};
