import React from 'react';
import { observer } from 'mobx-react-lite';
import armbeygja from '../images/teygja_armbeygja_small.png';
import armretta from '../images/teygja_armretta_small.png';
import framanverdlaeri from '../images/teygja_framanverdlaeri_small.png';
import aftanverdlaeri from '../images/teygja_aftanverdlaeri_small.png';
import axlir from '../images/teygja_axlir_small.png';
import bakvodvar_nidurtog from '../images/teygja_bakvodvar_nidurtog_small.png';
import bakvodvar_rodur from '../images/teygja_bakvodvar_rodur_small.png';
import brjostvodvar from '../images/teygja_brjostvodvar_small.png';
import fotapressa from '../images/teygja_fotapressa_small.png';
import kalfapressa from '../images/teygja_kalfapressa_small.png';
import innanverdlaeri from '../images/teygja_innanverdlaeri_small.png';
import utanverdlaeri from '../images/teygja_utanverdlaeri_small.png';
import kvidaefing from '../images/teygja_abs_small.png';
import bakaefing from '../images/teygja_bakaefing_small.png';

export const StretchEquipmentImageSmall = observer(({ number }) => {
  const image = numberMap[number];
  if (!image) return null;
  return (
    <div
      style={{
        position: 'absolute',
        bottom: 0,
        left: 10,
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        top: 0,
      }}
    >
      <img src={image} width="80" style={{ maxHeight: 80 }} alt="mynd æfingar" />
    </div>
  );
});

const numberMap = {
  1: armbeygja,
  2: armretta,
  3: framanverdlaeri,
  4: aftanverdlaeri,
  5: brjostvodvar,
  6: bakvodvar_rodur,
  7: innanverdlaeri,
  8: utanverdlaeri,
  9: bakvodvar_nidurtog,
  10: axlir,
  11: fotapressa,
  12: kalfapressa,

  13: kvidaefing,
  14: bakaefing,
};
