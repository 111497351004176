import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import { Checkmark } from '../icons/Checkmark';
import { Box } from '../components/Box';

const StyledStepCircle = styled.div((p) => ({
  height: '1.125rem',
  width: '1.125rem',
  flexShrink: 0,
  borderRadius: '50%',
  border: '4px solid ' + (p.active ? p.theme.main : '#c9c9c9'),
  color: '#fff',
  backgroundColor: p.showCheck ? p.theme.main : 'transparent',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StepCircle = observer(({ active, showCheck }) => {
  return (
    <StyledStepCircle active={active} showCheck={showCheck}>
      {showCheck && <Checkmark />}
    </StyledStepCircle>
  );
});
const StepLine = styled.div((p) => ({
  flexGrow: 1,
  width: '100%',
  height: 4,
  backgroundColor: p.active ? p.theme.main : '#c9c9c9',
}));

export const ProgressSteps = observer(({ count, current }) => {
  return (
    <Box flex alignItems="center" justifyContent="space-between">
      <StepCircle active showCheck={current > 0} />
      {Array.from({ length: count - 1 }).map((_, i) => (
        <React.Fragment key={i}>
          <StepLine active={current >= i + 1} />
          <StepCircle active={current >= i + 1} showCheck={current > i + 1} />
        </React.Fragment>
      ))}
    </Box>
  );
});
