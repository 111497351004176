import React from 'react';
import { observer } from 'mobx-react-lite';

import { Card } from '../components/Card';
import { Box } from '../components/Box';
import { Button } from '../components/Button';

export const ColorChooser = observer(({ value, onChange }) => {
  return (
    <Card>
      <Box flex alignItems="center" justifyContent="space-between">
        <Button
          bg={value === 'red' ? 'red' : 'redTrans'}
          color={value === 'red' ? 'white' : 'black'}
          onClick={() => onChange('red')}
        >
          Rauð
        </Button>
        <Button
          bg={value === 'yellow' ? 'orange' : 'orangeTrans'}
          color={value === 'yellow' ? 'white' : 'black'}
          onClick={() => onChange('yellow')}
        >
          Gul
        </Button>
      </Box>
    </Card>
  );
});
