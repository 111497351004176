import React from 'react';
import { observer } from 'mobx-react-lite';
import armbeygja from '../images/armbeygja.png';
import armretta from '../images/armretta.png';
import framanverdlaeri from '../images/framanverdlaeri.png';
import aftanverdlaeri from '../images/aftanverdlaeri.png';
import axlir from '../images/axlir.png';
import bakvodvar_nidurtog from '../images/bakvodvar_nidurtog.png';
import bakvodvar_rodur from '../images/bakvodvar_rodur.png';
import brjostvodvar from '../images/brjostvodvar.png';
import fotapressa from '../images/fotapressa.png';
import innanverdlaeri from '../images/innanverdlaeri.png';
import utanverdlaeri from '../images/utanverdlaeri.png';
import kvidaefing from '../images/abs.png';
import bakaefing from '../images/bakaefing.png';

export const EquipmentImage = observer(({ number }) => {
  const image = numberMap[number];
  if (!image) return null;
  return <img src={image} width="150" alt="mynd æfingar" />;
});

export const numberMap = {
  1: armbeygja,
  2: armretta,
  3: framanverdlaeri,
  4: aftanverdlaeri,
  5: brjostvodvar,
  6: bakvodvar_rodur,
  7: innanverdlaeri,
  8: utanverdlaeri,
  9: bakvodvar_nidurtog,
  10: axlir,
  11: fotapressa,
  12: fotapressa,

  13: kvidaefing,
  14: bakaefing,
};
