import React from 'react';

export const ChevronUp = () => (
  <svg width="16" height="9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.438 7.792L7.69 1.539c.17-.171.447-.171.618 0h0l6.254 6.253"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
