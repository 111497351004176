import React from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';

import { Page } from '../components/Page';
import { BackButton } from '../components/BackButton';
import { Box } from '../components/Box';
import { Text } from '../components/Text';
import { Card } from '../components/Card';
import { Button } from '../components/Button';
import { NumberInput } from '../components/NumberInput';
import { useStore } from '../models/Store';
import { Select } from '../components/Select';
import { Input } from '../components/Input';
import { useEnduranceOptions } from '../hooks/useEnduranceOptions';
import hlauphjol from '../images/hlaup_hjol.png';

export const EnduranceExerciseMain = observer(() => {
  const store = useStore();
  const history = useHistory();
  const opts = useEnduranceOptions();
  return (
    <Page>
      <BackButton link="/exercise/endurance/" />
      <Card mt="24" style={{ textAlign: 'center' }}>
        <Box column flex center>
          <img style={{ maxWidth: '80%' }} src={hlauphjol} alt="Göngubretti, hjól eða skíðatæki" />
          <Text mt="12" type="normal22" children="20 - 60 mín" />
        </Box>
      </Card>
      <Box mt="24" w="100%">
        <Select options={opts} value={store.enduranceType} onSelect={store.setEnduranceType} />
      </Box>
      {store.enduranceType === 'annad' && (
        <Box mt="12">
          <Input
            placeholder="Hvernig æfing varð fyrir valinu?"
            value={store.enduranceOther}
            onChange={store.setEnduranceOther}
          />
        </Box>
      )}
      <Box mt="24">
        <NumberInput
          onIncrease={store.increaseEnduranceMinutes}
          onDecrease={store.decreaseEnduranceMinutes}
          onChange={store.setEnduranceMinutes}
          unit="mín"
          value={store.enduranceMinutes}
        />
      </Box>
      <Button
        mt="32"
        children="Ljúka æfingu"
        onClick={async () => {
          const success = await store.finishEnduranceExercise();
          if (success) {
            history.push('/');
          }
        }}
      />
    </Page>
  );
});
