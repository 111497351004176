import React from 'react';

export const BarChartIcon = (props) => (
  <svg viewBox="0 0 63 63" width="63" height="63">
    <defs>
      <clipPath id="_clipPath_1bjCBqWaZkq16TYkpnQ8JkjghX75Q1dm">
        <rect width="63" height="63" />
      </clipPath>
    </defs>
    <g clipPath="url(#_clipPath_1bjCBqWaZkq16TYkpnQ8JkjghX75Q1dm)">
      <path
        d=" M 12.532 58.334 L 17.934 58.334 L 17.934 21.926 L 12.532 21.926 L 12.532 58.334 Z "
        fill="rgb(139,139,139)"
      />
      <path
        d=" M 4.783 58.334 L 10.179 58.334 L 10.179 38.138 L 4.783 38.138 L 4.783 58.334 Z "
        fill="rgb(139,139,139)"
      />
      <path
        d=" M 20.272 58.334 L 25.684 58.334 L 25.684 27.428 L 20.272 27.428 L 20.272 58.334 Z "
        fill="rgb(139,139,139)"
      />
      <path
        d=" M 28.028 10.083 L 28.028 58.334 L 33.428 58.334 L 33.428 10.083 L 28.028 10.083 Z "
        fill="rgb(37,169,189)"
      />
      <path
        d=" M 60.202 61.425 L 1.948 61.425 L 1.948 6.689 L 2.615 6.689 L 2.615 60.39 L 60.202 60.39 L 60.202 61.425 Z "
        fill="rgb(115,117,119)"
      />
      <path
        d=" M 0.057 7.841 L 2.282 3.944 L 4.506 7.841 L 0.057 7.841 Z "
        fill="rgb(115,117,119)"
      />
      <path
        d=" M 58.932 57.946 L 62.839 60.382 L 58.953 62.851 L 58.932 57.946 Z "
        fill="rgb(115,117,119)"
      />
      <path
        d=" M 43.518 58.334 L 48.93 58.334 L 48.93 31.027 L 43.518 31.027 L 43.518 58.334 Z "
        fill="rgb(139,139,139)"
      />
      <path
        d=" M 51.273 23.703 L 51.273 58.334 L 56.674 58.334 L 56.674 23.703 L 51.273 23.703 Z "
        fill="rgb(139,139,139)"
      />
      <path
        d=" M 35.777 58.334 L 41.179 58.334 L 41.179 16.77 L 35.777 16.77 L 35.777 58.334 Z "
        fill="rgb(139,139,139)"
      />
    </g>
  </svg>
);
