import React from 'react';
import banner from '../images/signin-banner.svg';
import { Page } from '../components/Page';
import { Box } from '../components/Box';
import { JanusBigLogo } from '../icons/JanusBigLogo';

export const Splash = () => {
  return (
    <Page style={{ padding: 0 }}>
      <Box flex flexDirection="column" style={{ height: '100vh' }}>
        <div style={{ flex: 1, width: '100%' }}>
          <Box center flex style={{ height: '100%', width: '100%' }}>
            <JanusBigLogo />
          </Box>
        </div>
        <img src={banner} alt="fólk að æfa" style={{ marginBottom: -5 }} />
        <div style={{ height: '30%', backgroundColor: '#25A9BD', width: '100%' }}></div>
      </Box>
    </Page>
  );
};
