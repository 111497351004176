import React from 'react';
import styled from 'styled-components/macro';

const StyledInput = styled.input(p => ({
  transition: '0.3s',
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: p.theme.border,
  padding: 18,
  borderRadius: 10,
  width: '100%',
  ...(p.error && {
    outline: 'none',
    borderColor: p.theme.red
  }),
  ...(!p.disabled &&
    !p.error && {
      ':focus': {
        outline: 'none',
        borderColor: p.theme.secondary
      }
    }),
  '::placeholder': {
    color: p.theme.main80
  }
}));

export const Input = props => {
  const { onChange, disabled, ...rest } = props;
  const change = e => (disabled ? null : onChange(e.target.value));
  return <StyledInput {...rest} onChange={change} disabled={disabled} />;
};
