import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import banner from '../images/signin-banner.svg';
import { Page } from '../components/Page';
import { Box } from '../components/Box';
import { H1, Text } from '../components/Text';
import { JanusBigLogo } from '../icons/JanusBigLogo';
import { LogoutButton } from '../components/LogoutButton';

export const InactiveUser = observer(() => {


  return (
    <Page style={{ padding: 0 }}>
      <Box flex flexDirection="column" style={{ minHeight: '100vh' }}>
        <Box padding="24" style={{ width: '100%' }}>
          <Box flex vCenter>
            <JanusBigLogo />
          </Box>
          <Box flex center mt="24">
            <H1>Innskráning
              <LogoutButton />
            </H1>
          </Box>
          <Box flex center mt="80" mb="80" padding="24">
            <Text color="red">Þessi notandi er ekki virkur</Text>
          </Box>
        </Box>
        <img src={banner} style={{ marginBottom: -15, width: '100%' }} alt="people exercise" />
        <FillBackground />
      </Box>
    </Page>
  );
});

const FillBackground = styled.div((p) => ({
  backgroundColor: p.theme.main,
  width: '100%',
  flex: 1,
  minHeight: 20,
}));
