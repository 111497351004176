import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import { Box } from './Box';

const StyledButton = styled(Box)((p) => ({
  width: '100%',
  color: p.color ? p.theme[p.color] : p.theme.white,
  padding: 16,
  fontSize: 18,
  textAlign: 'center',
  backgroundColor: p.disabled ? p.theme.buttonDisabled : p.bg ? p.theme[p.bg] : p.theme.button,
  borderRadius: 10,
}));

export const Button = observer((props) => {
  const { onClick, disabled, ...rest } = props;
  return (
    <StyledButton
      as="button"
      {...rest}
      onClick={disabled ? null : onClick}
      disabled={disabled}
      type="button"
    />
  );
});
