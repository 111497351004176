import axios from 'axios';

const BASE = 'https://api.janusheilsuefling.is';
// const BASE = 'https://api-staging.janusheilsuefling.is';
//const BASE = 'http://localhost:8200';

export const api = {
  authenticate: (username, password) => {
    return request.post(`${BASE}/auth/`, { username, password });
  },
  change_password: (old_password, new_password) => {
    return request.post(`${BASE}/set_password/`, {
      old_password,
      new_password,
    });
  },
  me: () => {
    return request.get(`${BASE}/me/`);
  },
  get_events: () => {
    return request.get(`${BASE}/events/`);
  },
  get_event_by_id: (event_id) => {
    return request.get(`${BASE}/events/${event_id}`);
  },
  create_endurance_exercise: (data) => {
    return request.post(`${BASE}/endurance/`, data);
  },
  remove_endurance_exercise: (id) => {
    return request.delete(`${BASE}/endurance/${id}/`);
  },
  update_endurance_exercise: (id, data) => {
    return request.patch(`${BASE}/endurance/${id}/`, data);
  },
  get_next_strength_exercise: () => {
    return request.get(`${BASE}/strength/?next=true`);
  },
  update_strength_exercise_set: (set_id, data) => {
    return request.patch(`${BASE}/sets/${set_id}/`, data);
  },
  update_strength_exercise: (exercise_id, data) => {
    return request.patch(`${BASE}/strength/${exercise_id}/`, data);
  },
};

const request = {
  get: (url, data = {}, options = {}) => {
    return handle_request({ url, data, options, method: 'get' });
  },
  post: (url, data = {}, options = {}) => {
    return handle_request({ url, data, options, method: 'post' });
  },
  put: (url, data = {}, options = {}) => {
    return handle_request({ url, data, options, method: 'put' });
  },
  patch: (url, data = {}, options = {}) => {
    return handle_request({ url, data, options, method: 'patch' });
  },
  delete: (url, data = {}, options = {}) => {
    return handle_request({ url, data, options, method: 'delete' });
  },
};

const handle_request = async (requestObj) => {
  try {
    const res = await axios(requestObj);
    return {
      data: res.data,
      status: res.status,
      error: false,
    };
  } catch (error) {
    const cpy = { ...error };
    if (cpy && cpy.response) {
      return {
        data: cpy.response.data || cpy.response.statusText || 'Error',
        status: cpy.response.status,
        error: true,
      };
    }
  }
  return { error: true };
};
