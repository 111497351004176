import React from 'react';

export const MeasuringTapeIcon = () => (
  <svg width="63" height="63" viewBox="0 0 63 63" fill="none">
    <path
      d="M11.0742 24.1171L7.38281 38.8828H36.9141V22.2714L27.3201 17.1548L11.0742 24.1171Z"
      fill="#FF641A"
    />
    <path
      d="M48.1113 42.3527V49.2926L44.2969 55.4941H40.6055L38.538 53.7221L36.9141 55.4941H33.2227L31.5247 53.9436L29.5312 55.4941H25.8398L24.2159 53.8699L22.1484 55.4941H20.3027C19.6754 55.4941 19.0473 55.4572 18.457 55.3833L16.7591 53.0208L14.7656 54.7188C6.27527 52.3195 0 44.4568 0 35.1914V22.2715L3.69141 24.1172C3.69141 30.8723 10.0405 36.2618 18.457 36.9632C19.0843 37.0001 19.6754 37.0371 20.3027 37.0371H44.2969L48.1113 42.3527Z"
      fill="#FFDA2D"
    />
    <path
      d="M48.1113 42.353V49.2928L44.2969 55.4944H40.6055L38.538 53.7224L36.9141 55.4944H33.2227L31.5247 53.9438L29.5312 55.4944H25.8398L24.2159 53.8701L22.1484 55.4944H20.3027C19.6754 55.4944 19.0473 55.4574 18.457 55.3835V36.9634C19.0843 37.0003 19.6754 37.0373 20.3027 37.0373H44.2969L48.1113 42.353Z"
      fill="#FDBF00"
    />
    <path
      d="M36.9141 22.2715C36.9141 30.5402 28.7931 37.0371 18.457 37.0371C8.12097 37.0371 0 30.5402 0 22.2715C0 14.0027 8.12097 7.50586 18.457 7.50586C28.7931 7.50586 36.9141 14.0027 36.9141 22.2715Z"
      fill="#FDBF00"
    />
    <path d="M63 55.4941H44.2969V37.0371H63V55.4941Z" fill="#D8D8FC" />
    <path d="M55.4941 44.4199H51.8027V48.1113H55.4941V44.4199Z" fill="#47568C" />
    <path d="M40.6055 44.4199H36.9141V55.4941H40.6055V44.4199Z" fill="#FF641A" />
    <path d="M33.2227 48.1113H29.5312V55.4941H33.2227V48.1113Z" fill="#FF641A" />
    <path d="M25.8398 44.4199H22.1484V55.4941H25.8398V44.4199Z" fill="#FF641A" />
    <path
      d="M18.457 48.1113V55.3833C17.2016 55.3096 15.9471 55.0512 14.7656 54.7188V48.1113H18.457Z"
      fill="#FF9100"
    />
    <path
      d="M36.9141 22.2715C36.9141 30.5402 28.7931 37.0371 18.457 37.0371V7.50586C28.7931 7.50586 36.9141 14.0027 36.9141 22.2715Z"
      fill="#FF9100"
    />
    <path
      d="M18.457 13.043C11.222 13.043 5.53711 17.1034 5.53711 22.2715C5.53711 27.4393 11.222 31.5 18.457 31.5C25.6921 31.5 31.377 27.4393 31.377 22.2715C31.377 17.1034 25.6921 13.043 18.457 13.043Z"
      fill="#FFDA2D"
    />
    <path
      d="M31.377 22.2715C31.377 27.4393 25.6921 31.5 18.457 31.5V13.043C25.6921 13.043 31.377 17.1034 31.377 22.2715Z"
      fill="#FDBF00"
    />
    <path d="M23.9941 20.4258H12.9199V24.1172H23.9941V20.4258Z" fill="#FDBF00" />
    <path d="M23.9941 20.4258H18.457V24.1172H23.9941V20.4258Z" fill="#FF9100" />
  </svg>
);
