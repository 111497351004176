import React from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import { BarChartIcon } from '../icons/BarChartIcon';
import { DumbellIcon } from '../icons/DumbellIcon';
import { StretchIcon } from '../icons/StretchIcon';
import { HeartbeatIcon } from '../icons/HeartbeatIcon';
import { MeasuringTapeIcon } from '../icons/MeasuringTapeIcon';
import { RunningShoesIcon } from '../icons/RunningShoesIcon';
import { Text } from './Text';
import { useStore } from '../models/Store';

export const MainMenu = observer(() => {
  const store = useStore();
  const disabled = !store.nextStrength;
  return (
    <>
      <MainMenuItems>
        <MainMenuItem
          title="Styrktarþjálfun"
          disabled={disabled}
          icon={DumbellIcon}
          path="/exercise/strength"
        />
        <MainMenuItem title="Þolþjálfun" icon={RunningShoesIcon} path="/exercise/endurance" />
        <MainMenuItem
          title="Styrktaræfing með teygju"
          icon={StretchIcon}
          disabled={disabled}
          path="/exercise/stretch"
        />
        <MainMenuItem title="Mælingar" icon={MeasuringTapeIcon} path="/measurements" />
        {store.me && store.me.karvonen_50 && (
          <MainMenuItem title="Karvonen" icon={HeartbeatIcon} path="/karvonen" />
        )}
        <MainMenuItem title="Saga" icon={BarChartIcon} path="/history" />
      </MainMenuItems>
    </>
  );
});

const MainMenuItem = observer(({ title, icon: Icon, path, disabled = false }) => {
  const [touching, setTouching] = React.useState(false);
  return (
    <MainMenuCard
      disabled={disabled}
      {...(!disabled && {
        onTouchStart: () => setTouching(true),
        onTouchEnd: () => setTouching(false),
        onTouchCancel: () => setTouching(false),
      })}
      {...(disabled && {
        onClick: (e) => {
          e.preventDefault();
          e.stopPropagation();
        },
      })}
      to={path}
      touching={touching}
    >
      <Icon />
      <Text type="bold20" mt="24">
        {title}
      </Text>
    </MainMenuCard>
  );
});

const MainMenuItems = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridGap: 10,
  width: '100%',
});

const MainMenuCard = styled(({ touching, disabled, ...rest }) => <Link {...rest} />)((p) => ({
  textAlign: 'center',
  wordBreak: 'break-word',
  width: '100%',
  height: 160,
  backgroundColor: '#fff',
  borderRadius: 10,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  transition: 'transform 100ms ease',
  ...(!p.disabled && {
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
  }),
  ...(p.disabled && {
    opacity: 0.5,
  }),
  ...(p.touching && {
    transform: 'scale(1.04)',
  }),
}));
