import React from 'react';
import { observer } from 'mobx-react-lite';

import { BackButton } from './BackButton';
import { Box } from './Box';
import { H1 } from './Text';

export const PageHeader = observer(({ title, backLink = '/' }) => (
  <>
    <BackButton link={backLink} />
    <Box mt="24" mb="24" flex center>
      <H1 bold center>
        {title}
      </H1>
    </Box>
  </>
));
