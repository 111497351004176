import React from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import { useStore } from '../models/Store';
import { Page } from '../components/Page';
import { BackButton } from '../components/BackButton';
import { Box } from '../components/Box';
import { ProgressSteps } from '../components/ProgressSteps';
import { Text } from '../components/Text';
import { Button } from '../components/Button';
import { Checkmark } from '../icons/Checkmark';
import { Card } from '../components/Card';
import { EquipmentImageSmall } from '../components/EquipmentImageSmall';

const StyledCheckmark = styled.div(p => ({
  minHeight: '2.5rem',
  minWidth: '2.5rem',
  borderRadius: '50%',
  border: '2px solid rgba(33, 33, 33, 0.2)',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  color: '#fff',
  ...(p.checked && {
    backgroundColor: p.theme.main,
    borderColor: p.theme.main
  })
}));
const ExerciseCheckmark = observer(({ checked }) => {
  return (
    <StyledCheckmark checked={checked}>
      {checked && <Checkmark height="16" width="16" />}
    </StyledCheckmark>
  );
});
const ExerciseMainEntry = observer(({ set, disabled, overrides }) => {

  let cardStyles = {}
  let linkStyles = {}
  if (disabled) {
    cardStyles = {
      textDecoration: 'line-through',
      opacity: 0.3
    }
    linkStyles = {
      pointerEvents: 'none'
    }
  }

  return (
    <Box mt="16">
      <Link to={disabled ? '' : `/exercise/strength/main/${set.id}`} style={linkStyles}>
        <Card
          condensed
          accentColor={set.done ? undefined : 'red'}
          bgColor={set.done ? '#EAEAEA' : '#fff'}
          style={cardStyles}
        >
          <Box flex alignItems="center" mt="4" mb="4">
            <EquipmentImageSmall number={set.equipment.equipment_no} />
            <Box grow flex column justifyContent="center" style={{ marginLeft: '5rem' }}>
              <Text children={'Stöð ' + set.equipment.equipment_no} />
              <Text type="semibold20" mt="8" children={set.equipment.name} />
            </Box>
            <Box ml="12">
              <ExerciseCheckmark checked={set.done} />
            </Box>
          </Box>
        </Card>
      </Link>
    </Box>
  );
});
export const StrengthExerciseMain = observer(() => {
  const store = useStore();

  return (
    <Page>
      <BackButton link="/exercise/strength/warmup" />
      <Box mt="32">
        <ProgressSteps count={3} current={1} />
      </Box>
      <Text type="semibold24" mt="32" children="Aðalþáttur" />
      {store.nextStrength && (
        <Text mt="16">
          {store.nextStrength.howManyCompleted} / {store.nextStrength.sets.length-store.nextStrength.overrides.filter(o => o.hide_equipment===true).length} æfingar kláraðar
        </Text>
      )}

      <Box mt="16">
        {store.nextStrength &&
          store.nextStrength.sets.map(set => {
            return <ExerciseMainEntry key={set.id} set={set} overrides={store.nextStrength.overrides.filter(o => o.equipment === set.equipment.equipment_no)} disabled={store.nextStrength.overrides.filter(o => o.hide_equipment===true).map(o => o.equipment).includes(set.equipment.equipment_no)} />;
          })}
        <Link to="/exercise/strength/stretch">
          <Button mt="32" children="Áfram" />
        </Link>
      </Box>
    </Page>
  );
});
