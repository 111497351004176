import React from 'react';

export const DumbellIcon = (props) => (
  <svg width="63" height="63" viewBox="0 0 63 63" fill="none">
    <path d="M55.1693 0L0 55.1693L7.8307 63L63 7.8307L55.1693 0Z" fill="#F2F2FC" />
    <path
      d="M59.0839 3.91568L3.9151 59.0845L7.83039 62.9998L62.9992 7.83097L59.0839 3.91568Z"
      fill="#D8D8FC"
    />
    <path d="M44.7285 0L36.8978 7.8307L55.1692 26.1022L63 18.2715L44.7285 0Z" fill="#47568C" />
    <path
      d="M7.8307 36.8979L0 44.7287L18.2715 63.0001L26.1022 55.1694L7.8307 36.8979Z"
      fill="#47568C"
    />
    <path
      d="M53.8637 9.13622L46.0331 16.9668L55.1688 26.1025L62.9994 18.2719L53.8637 9.13622Z"
      fill="#2C3B73"
    />
    <path
      d="M9.13569 53.8648L18.2714 63.0005L26.1019 55.1699L16.9663 46.0342L9.13569 53.8648Z"
      fill="#2C3B73"
    />
    <path d="M34.2876 0L26.4568 7.8307L55.1692 36.5431L62.9999 28.7124L34.2876 0Z" fill="#61729B" />
    <path
      d="M7.8307 26.457L0 34.2877L28.7124 63.0001L36.5431 55.1694L7.8307 26.457Z"
      fill="#61729B"
    />
    <path
      d="M48.6434 14.3564L40.8128 22.187L55.1689 36.5431L62.9995 28.7125L48.6434 14.3564Z"
      fill="#47568C"
    />
    <path
      d="M14.3562 48.6444L28.7123 63.0005L36.5429 55.1699L22.1868 40.8138L14.3562 48.6444Z"
      fill="#47568C"
    />
  </svg>
);
