import React from 'react';
import { observer } from 'mobx-react-lite';
import { IonSelect, IonSelectOption } from '@ionic/react';
import styled from 'styled-components';

export const Select = observer(({ options, onSelect, value }) => {
  return (
    <StyledSelect
      value={value}
      interface="action-sheet"
      onIonChange={(e) => onSelect(e.target.value)}
      mode="md"
    >
      {options.map((option, index) => (
        <IonSelectOption key={index} value={option.value}>
          {option.display}
        </IonSelectOption>
      ))}
    </StyledSelect>
  );
});

const StyledSelect = styled(IonSelect)((p) => ({
  backgroundColor: '#fff',
  transition: '0.3s',
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: p.theme.border,
  padding: 18,
  ...p.theme.text.normal18,
  borderRadius: 10,
  width: '100%',
  ':focus': {
    outline: 'none',
    borderColor: p.theme.secondary,
  },
}));
