import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';

import { MainMenu } from '../components/MainMenu';
import { SettingsModal } from '../components/SettingsModal';
import { Page } from '../components/Page';
import { H1 } from '../components/Text';
//import { SettingsIcon } from '../icons/SettingsIcon';
import { useStore } from '../models/Store';
import { Splash } from './Splash';
import { Box } from '../components/Box';
import { SplitLine } from '../components/SplitLine';
import { LogoutButton } from '../components/LogoutButton';

export const Home = observer(() => {
  const store = useStore();
  if (!store.me) return <Splash />;

  return (
    <Page>
      <Header>
        <H1>Góðan daginn</H1>
        {/* <SettingsIcon onClick={store.openSettingsModal} /> */}
      </Header>
      <H1 bold>
        {store.me.name}
        <LogoutButton />
      </H1>

      <Box mt="24" mb="24">
        <SplitLine />
      </Box>
      <Box mt="24">
        <MainMenu />
      </Box>
      <SettingsModal open={store.settingsModalOpen} onClose={store.closeSettingsModal} />
    </Page>
  );
});

const Header = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});
